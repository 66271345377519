import React from 'react'
import axios from 'axios'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button, Card,
  CardBody,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
  Table
} from "reactstrap";
import { Link } from 'react-router-dom'
import {confirmAlert} from "react-confirm-alert";
import jwt from "jsonwebtoken";
import Pagination from "react-js-pagination";
import PrintPickup from "./PrintPickup";
import ReactToPrint from "react-to-print";
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select'
import {USER_ROLE} from "../../constants";
import ImageUploading from "react-images-uploading";
import RingoPrintPickup from "./RingoPrintPickup";
let timerId;

class Pickup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 20,
        from: 0,
        page: 1
      },
      modalPrintPackagesToggle: false,
      modalToggle: false,
      modalEditToggle: false,
      modalStatusToggle: false,
      modalToggleUpdatePickup: false,
      modalPickupInfo: {},
      currUser: {},
      listCustomer: [],
      selectedCustomer: {
        id: '',
        name: '',
        khCode: '',
        phone: '',
        address: ''
      },
      createPickup: {
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerPhone: "",
        note: "",
        paymentType: 'Tiền Mặt',
        statusType: "Chưa nhận",
        location: "HCM",
        verhicle: "Xe máy",
      },
      editPickup: {
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerPhone: "",
        note: "",
        location: "HCM"
      },
      isEdit: false,
      printId: '',
      printData: '',
      loading: false,
      date: new Date(),
      search: '',
      qStatus: '',
      searchBy: 'pickupId',
      location: '',
      locationOption: {
        HCM: "HCM",
        HN: 'HN',
        ĐN: 'ĐN'
      },
      locationOption2: {
        HCM: "HCM",
        HN: 'HN',
        ĐN: 'ĐN'
      },
      images: [],
      imagesEdit: [],
      isMyPickup: false,
      listServices: [],
      groupSelected: [],
    }
    this.onDeletePickup = this.onDeletePickup.bind(this)
    this.onCreatePacking = this.onCreatePacking.bind(this)
    this.openEditModal = this.openEditModal.bind(this)
    this.openEditStatusModal = this.openEditStatusModal.bind(this)
    this.onEditPickup = this.onEditPickup.bind(this)
    this.onCreatePickup = this.onCreatePickup.bind(this);
    this.onEditPickupChange = this.onEditPickupChange.bind(this);
    this.toggleUpdatePickup = this.toggleUpdatePickup.bind(this);
    this.onCreatePickupChange = this.onCreatePickupChange.bind(this);
    this.onSelectedCustomer = this.onSelectedCustomer.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSelectedEditCustomer = this.onSelectedEditCustomer.bind(this);
    this.onClickPrintPickup = this.onClickPrintPickup.bind(this);
    this.printPackagesToggle = this.printPackagesToggle.bind(this);
    this.handleDatetoSelect = this.handleDatetoSelect.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toggle = this.toggle.bind(this);
    this.searchStatus = this.searchStatus.bind(this);
    this.searchBy = this.searchBy.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.setImages = this.setImages.bind(this);
    this.onMyPickupChange = this.onMyPickupChange.bind(this);
    this.getAllService = this.getAllService.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({ currUser, createPickup: {...this.state.createPickup, owner: currUser.displayName} })
    await this.getAllPickup()
    await this.getListCustomer()
    this.getAllService()
  }

  async getAllService(params = {}) {
    try {
      let url = `/get-all-service-name`
      const res = await axios.get(url)
      if (res && res.data) {
        // filter by role user if ops
        let listServices = res.data
        if (this.state.currUser.role === USER_ROLE.OPS) {
          listServices = res.data.filter(e => e.type === 'fwd')
        }
        this.setState({listServices: listServices})
        // save to local storage
        localStorage.setItem('listServices', JSON.stringify(res.data))
      }
    } catch (e) {
      console.error(e)
    }
  }
  async onCreatePickup(e) {
    e.preventDefault();
    try {
      const { createPickup, images } = this.state
      let message = ''
      if (!createPickup.customerId) {
        message = 'chọn khách hàng'
      } else if (!createPickup.customerName) {
        message = 'nhập tên khách hàng'
      } else if (!createPickup.customerAddress) {
        message = 'nhập địa chỉ khách hàng'
      } else if (!createPickup.customerPhone) {
        message = 'nhập số điện thoại khách hàng'
      } else if (!createPickup.packs) {
        message = 'nhập số kiện'
      } else if (!createPickup.weight) {
        message = 'nhập cân nặng'
      } else if (!createPickup.service) {
        message = 'chọn dịch vụ'
      }

      let packageGroup = this.state.groupSelected.map(e => e.value).join(', ')
      if (!packageGroup) {
        message = 'chọn nhóm hàng'
      }
      if (message) {
        toast.error('Vui lòng ' + message)
        return
      }

      let imageLists = []
      if (images && images.length) {
        const formData = new FormData();
        images.forEach(i => {
          formData.append("photos", i.file);
        })
        const {data = []} = await axios({
          method: "post",
          url: "/upload-pickup",
          data: formData,
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
        });
        imageLists = data
      }

      await axios.post('/create-pickup', {...createPickup, images: imageLists.join(','), date: this.state.date, group: packageGroup})
      this.setState((prevState) => ({ modalToggle: !prevState.modalToggle, images: [] }));
      await this.getAllPickup()
    } catch (err) {
      toast.error('Đã có lỗi xảy ra ', err.error);
      return
    }
  }
  async onEditPickup(e) {
    const { editPickup, images, imagesEdit } = this.state
    let message = '', createPickup = editPickup
    let imageLists = []
    if (!createPickup.customerId) {
      message = 'chọn khách hàng'
    } else if (!createPickup.customerName) {
      message = 'nhập tên khách hàng'
    } else if (!createPickup.customerAddress) {
      message = 'nhập địa chỉ khách hàng'
    } else if (!createPickup.customerPhone) {
      message = 'nhập số điện thoại khách hàng'
    } else if (!createPickup.packs) {
      message = 'nhập số kiện'
    } else if (!createPickup.weight) {
      message = 'nhập cân nặng'
    } else if (!createPickup.service) {
      message = 'chọn dịch vụ'
    }

    let packageGroup = this.state.groupSelected.map(e => e.value).join(', ')
    if (!packageGroup) {
      message = 'chọn nhóm hàng'
    }

    if (message) {
      toast.error('Vui lòng ' + message)
      return
    }

    if (images && images.length) {
      const formData = new FormData();
      images.forEach(i => {
        formData.append("photos", i.file);
      })

      const {data = []} = await axios({
        method: "post",
        url: "/upload-pickup",
        data: formData,
        headers: {"Content-Type": "application/x-www-form-urlencoded"},
      });
      imageLists = data
    }


    if (imagesEdit && imagesEdit.length) {
      imageLists = [...imageLists, ...imagesEdit]
    }
    try {
      await axios.post(`/update-pickup/${editPickup.id}`, {...editPickup, date: this.state.date, images: imageLists.join(','), group: packageGroup})
      this.setState((prevState) => ({ modalEditToggle: !prevState.modalEditToggle, images: [] }));
      await this.getAllPickup()
      e.preventDefault();
    } catch (err) {
      toast.error('Đã có lỗi xảy ra');
      return
    }

  }

  handleChangeType(e) {
    this.setState({groupSelected: e})
  }

  toggle() {
    this.setState((prevState, props) => ({ modalToggle: !prevState.modalToggle }));
  }
  openEditModal(obj) {
    let imagesEdit = obj.images
    if (imagesEdit) {
      imagesEdit = imagesEdit.split(',')
    }

    if (obj.group) {
      let arr = obj.group.split(', ')
      // define arr label, value
      let arrGroupSelected = []
      arr.forEach(e => {
        arrGroupSelected.push({label: e, value: e})
      })
      this.setState({groupSelected: arrGroupSelected})
    }

    this.setState({editPickup: obj, imagesEdit: imagesEdit, date: moment(obj.date).subtract(7,'h')})
    this.setState((prevState, props) => ({ modalEditToggle: !prevState.modalEditToggle }));

    //const currentCustomer = this.state.listCustomer.filter(i => i.id === obj.id)?[0] : ''
    //this.setState({selectedCustomer: currentCustomer})

  }

  openEditStatusModal(obj) {
    this.setState({editPickup: obj, date: moment(obj.date).subtract(7,'h')})
    this.setState((prevState, props) => ({ modalEditStatusToggle: !prevState.modalEditStatusToggle }));
  }
  toggleUpdatePickup() {

  }

  onMyPickupChange(e) {
    this.setState({isMyPickup: e.target.checked}, () => {
      this.getAllPickup()
    })
  }

  onCreatePickupChange(e) {
    const { name, value } = e.target;
    let createPickupInfo = this.state.createPickup
    createPickupInfo[name] = value
    this.setState({ createPickup: createPickupInfo })
  }
  onEditPickupChange(e) {
    const { name, value } = e.target;
    let editPickupInfo = this.state.editPickup
    editPickupInfo[name] = value
    this.setState({ editPickup: editPickupInfo })
  }
  formatNumber (num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
 }
  async onDeletePickup(id) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this Pickup?",
        buttons: [
          {
            label: "Yes",
            onClick: async() => {
              const res = await axios.get(`/delete-pickup/${id}`)
              console.log(res)
              await self.getAllPickup()
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  async onCreatePacking(e) {
    // go to url
    e.preventDefault();
    try {
       //Router.push('/packing')
    } catch (e) {
      console.error(e)
    }
  }

  onSelectChange = (e) => {
    const { value } = e
    this.setState({ filterDateType: value })
  }
  onSelectedCustomer = (customer) => {
    console.log('change ', customer)
    if (customer) {
      const { id } = customer
      const temp = this.state.listCustomer.filter(e => e.id == id)?.[0]
      if (temp) {
        let createPickupInfo = this.state.createPickup
        createPickupInfo['customerName'] = temp.name
        createPickupInfo['customerId'] = temp.id
        createPickupInfo['customerAddress'] = temp.address
        createPickupInfo['customerPhone'] = temp.phone
        this.setState({ selectedCustomer: temp, createPickup: createPickupInfo })

      }
    }

  }
  handleInputChange(e) {
    const inputValue = e;
    // Clear any existing timer
    clearTimeout(timerId);
    // Set a new timer to delay the API call
    timerId = setTimeout(() => {
      console.log(inputValue);
      this.getListCustomer({ search: inputValue });
    }, 500);
  }
  onSelectedEditCustomer = (customer) => {
    console.log('change ', customer)
    if (customer) {
      const { id } = customer
      const temp = this.state.listCustomer.filter(e => e.id == id)?.[0]
      if (temp) {
        let editPickupInfo = this.state.editPickup
        editPickupInfo['customerName'] = temp.name
        editPickupInfo['customerId'] = temp.id
        editPickupInfo['customerAddress'] = temp.address
        editPickupInfo['customerPhone'] = temp.phone
        this.setState({ selectedCustomer: temp, editPickup: editPickupInfo })

      }
    }
  }
  async getAllPickup(params = {}) {
    try {
      const { searchBy, search = '', data, isMyPickup } = this.state
      this.setState({loading: true})
      let res
      params.page = params.page  || data?.page
      let url = `/get-all-pickup?page=${params.page || 1}&search=${search}&status=${this.state.qStatus}`
      if (isMyPickup) {
        url += `&nvgn=${this.state.currUser.displayName}`
      }
      if (searchBy === 'owner') {
        url += `&searchBy=${searchBy}`
      }


      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      if (this.state.location) {
        url +=`&location=${this.state.location}`
      }

      if (this.state.currUser.role === USER_ROLE.MANAGER && this.state.currUser.location) {
        url +=`&location=${this.state.currUser.location}`
        const options = {
          [this.state.currUser.location]: this.state.currUser.location
        }
        console.log(options)
        this.setState({locationOption: options})
        this.forceUpdate()
      }
      res = await axios.get(url)
      if (res && res.data) {
        console.log(res.data)
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }
  async getListCustomer(params = {search: ''}) {
    try {
      let url = `/get-all-customer?page=${params.page || 1}&size=10&search=${params.search}`
      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT
          || this.state.currUser.role === USER_ROLE.OPS || this.state.currUser.role === USER_ROLE.CUSTOMER) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      const res = await axios.get(url )
      if (res && res.data) {
        console.log(res.data)
        const list = res.data.list.map(i => {
          return {
            ...i,
            value: i.id,
            label: `KHTP${i.id} (${i.billupCount || 0})`
          }
        })
        this.setState({listCustomer: list || []})
      }
    } catch (e) {
      console.error(e)
    }
  }
  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllPickup({page: pageNumber})
  }

  printPackagesToggle() {
    this.setState((prevState) => ({
      modalPrintPackagesToggle: !prevState.modalPrintPackagesToggle,
    }));
  }

  onClickPrintPickup(obj) {
    this.setState({ printData: obj });
    this.printPackagesToggle();
  }
  handleDatetoSelect (date) {
    console.log('date ', date)
    this.setState({date})
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }
  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  isGiaoNhan() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.SHIPPER) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  formatDate = (str) => {
    return moment(str).format('DD/MM/YY HH:mm')
  }
  handleInput = (e) => {
    const { value } = e.target
    this.setState({ search: value })
  }
  onSearch = (e) => {
    this.getAllPickup({search: this.state.search})
  }
  searchStatus(val) {
    console.log(val)
    this.setState({qStatus: val})
  }
  searchBy(val) {
    this.setState({searchBy: val})
  }
  searchBylocation(val) {
    this.setState({location: val})
  }

  onChange2 = (imageList, addUpdateIndex) => {
    // data for submit
    this.setImages(imageList);
  };

  setImages(imageList) {
    this.setState({images: imageList})
  }

  onImageEditRemove = (index) => {
    let { imagesEdit } = this.state
    const filteredItems = imagesEdit.filter(item => item !== imagesEdit[index])
    this.setState({imagesEdit: filteredItems})
  }

  isRingoPost = (pkg) => {
    if (pkg && pkg.owner && (pkg.owner.toLowerCase().includes('ringo') ||  pkg.owner.toLowerCase().includes('rg -'))) {
      return false
    }
    return true
  }
  render() {
    const maxNumber = 12;
    const { data, editPickup, createPickup, locationOption } = this.state
    const paymentTypeOption = {
      CASH: "Tiền mặt",
      BANK: "Chuyển khoản",
      ALL: "Tiền mặt & Chuyển khoản",
    }
    const statusTypeOption = {
      PROGRESS: "Chưa nhận",
      PROCESSING: "Đang nhận",
      SUCCESS: "Đã nhận",
      COMPLETED: "Hoàn thành",
      CANCEL: "Thất bại",
      //DONE: 'Hoàn thành chứng từ'
    }

    const printPickup = () => {
      return `@media print {
        @page {
          size: A4 portrait;
          margin: 5mm;
          margin-top: 5mm;
        }  }
        `;
    };

    const groupTypeOption2 = [
      {
        label: "Hàng liên quan đến thịt - trứng - sữa - hạt giống",
        value: "Hàng liên quan đến thịt - trứng - sữa - hạt giống",
      },
      {
        label: "Hàng yến",
        value: "Hàng yến",
      },
      {
        label: "Hàng có pin - cồn - cháy nổ",
        value: "Hàng có pin - cồn - cháy nổ",
      },
      {
        label: "Hàng thương hiệu",
        value: "Hàng thương hiệu",
      },
      {
        label: "Hàng thực phẩm",
        value: "Hàng thực phẩm",
      },
      {
        label: "Hàng mỹ phẩm",
        value: "Hàng mỹ phẩm",
      },
      {
        label: "Hàng thuốc tây - thuốc nam - TPCN dạng viên",
        value: "Hàng thuốc tây - thuốc nam - TPCN dạng viên",
      },
      {
        label: "Hàng quần áo - đồ gia dụng",
        value: "Hàng quần áo - đồ gia dụng",
      },
      {
        label: "Hàng đông lạnh",
        value: "Hàng đông lạnh",
      },
      {
        label: "Hàng cây cảnh",
        value: "Hàng cây cảnh",
      },
      {
        label: "Hàng làm từ da động vật",
        value: "Hàng làm từ da động vật",
      }
    ];

    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active> Pickup </BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col sm={12} className='ml-auto'>
            <>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.onSearch()
                  }}
                >
                  <Row className="" style={{gap: "10px"}}>
                    <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                            onChange={(e) => this.searchStatus(e.target.value)}
                    >
                      <option value="">Tất cả trạng thái</option>
                      <option value="Chưa nhận">Chưa nhận</option>
                      <option value="Đang nhận">Đang nhận</option>
                      <option value="Đã nhận">Đã nhận</option>
                      <option value="Hoàn thành">Hoàn thành</option>
                    </select>
                    <div>
                      <Label className="mr-1">Search By: </Label>
                      <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                              onChange={(e) => this.searchBy(e.target.value)}
                      >
                        <option value="pickupId">Pickup Id</option>
                        <option value="owner">Người tạo</option>
                      </select>
                    </div>
                    <div>
                      <Label style={{marginRight: '8px'}}>Khu Vực: </Label>
                      <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                              onChange={(e) => this.searchBylocation(e.target.value)}
                      >
                        <option value="">Tất cả</option>
                        <option value="HCM">HCM</option>
                        <option value="HN">HN</option>
                        <option value="ĐN">ĐN</option>
                      </select>
                    </div>
                    {/*<div>*/}
                    {/*  <Label style={{marginRight: '8px'}}>Nhân viên Giao Nhận: </Label>*/}
                    {/*  <select className="custom-select" style={{width: '200px', marginRight: '10px'}}*/}
                    {/*          onChange={(e) => this.searchBylocation(e.target.value)}*/}
                    {/*  >*/}
                    {/*    <option value="">Tất cả</option>*/}
                    {/*    <option value="HCM">HCM</option>*/}
                    {/*    <option value="HN">HN</option>*/}
                    {/*    <option value="ĐN">ĐN</option>*/}
                    {/*  </select>*/}
                    {/*</div>*/}
                    <InputGroup style={{maxWidth: '400px'}}>
                      <Input
                        id="kgInput"
                        style={{minWidth: '150px'}}
                        name="code"
                        value={this.state.search}
                        placeholder='Tìm kiếm...'
                        onChange={this.handleInput}
                      />
                      <Button type="submit" className="btn-search ml-2">Search</Button>
                    </InputGroup>
                  </Row>
                </form>
              </CardBody>
            </>
          </Col>
        </Row>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Danh sách thông tin hàng</span>
            {
              this.isGiaoNhan() && (
                <Label check>
                  <Input
                    type='checkbox'
                    name='isMyPickup'
                    value={this.state.isMyPickup}
                    onChange={(e) => this.onMyPickupChange(e)}
                  />{' '}
                  Đơn hàng của tôi
                </Label>
              )
            }
            {this.checkPermission() && (
              <Button type="button" className="butt ml-auto" onClick={this.toggle}>+ Tạo thông tin hàng</Button>
            )}
          </CardHeader>
          <CardBody>
            {this.state.loading && (
              <div className="text-center">
                <span className="loader"></span>
              </div>
            )}
            <Table responsive bordered className="mb-0 table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Mã Pickup</th>
                <th>Thông tin người gửi</th>
                <th>Thông tin hàng</th>
                <th>Ngày lấy hàng</th>
                <th>Người tạo</th>
                <th>NVGN</th>
                <th>Trạng Thái</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {data.list &&
              data.list.map((obj, i) => (
                <tr key={i + 1}>
                  <td>{i + 1}</td>
                  <td><Link to={`/app/pickup-detail/${obj.id}`} >PK{obj.id}</Link></td>
                  <td>
                    <p><strong>Name:</strong> {obj.customerName}</p>
                    <p><strong>Phone:</strong> {obj.customerPhone}</p>
                    <p><strong>Address:</strong> {obj.customerAddress}</p>
                  </td>
                  <td>
                    <div>
                      <p><strong>Số kiện:</strong> {obj.packs}</p>
                      <p><strong>Weight:</strong> {obj.weight}</p>
                      <p><strong>Thông tin hàng:</strong> {obj.info}</p>
                      <p><strong>Loại xe:</strong> {obj.verhicle || 'Xe máy'}</p>
                    </div>
                  </td>
                  <td>{this.formatDate(obj.date)}</td>
                  <td>{obj.owner}</td>
                  <td>{obj.nvgn || ''}</td>
                  <td className='text-center'>
                    <button className={`text-nowrap btn-status ${obj.statusType ==='Hoàn thành' ? "btn-success" : "btn-warning"} ${
                      obj.statusType ==='Chưa nhận' ? "btn-danger" : ""
                    }`}>{obj.statusType}</button>

                  </td>
                  {this.checkPermission() ? (
                    <td>
                      <div style={{minWidth: '100px'}}>
                        <Button className='m-1' type="button" color="info" onClick={(e) => this.onClickPrintPickup(obj)}>
                          <i className="fas fa-print"></i></Button>
                        <Button className="m-1" type="button" color="info" onClick={(e) => this.openEditModal(obj)}><i className="fas fa-pen"></i></Button>

                        {
                          this.checkPermission2() && (
                            <Button className="m-1" type="button" color="danger" onClick={(e) => this.onDeletePickup(obj.id)}><i className="fas fa-trash"></i></Button>

                          )
                        }
                        {
                          !obj.Packing && (
                            <Button  className="m-1" type="button" color="warning"><Link title="Tạo đơn đóng gói" style={{color: 'black'}} to={`/app/packing?pickupId=${obj.id}`} target="_blank"><i className="fas fa-box"></i></Link></Button>
                          )
                        }
                      </div>
                    </td>
                  ) : (
                      <td>
                        <Button className='m-1' type="button" color="info" onClick={(e) => this.onClickPrintPickup(obj)}>
                          <i className="fas fa-print"></i></Button>
                      </td>
                  )}
                </tr>
              ))}
              </tbody>
            </Table>
          </CardBody>

          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={data.page}
            itemsCountPerPage={data.size}
            totalItemsCount={data.total}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            hideFirstLastPages={true}
          />

        </Card>
        <Row>
          {/* Toggle Form Create Pickup */}

          <Modal isOpen={this.state.modalToggle} toggle={this.toggle} >
            <Form>
              <ModalHeader toggle={this.toggle}>Tạo thông tin hàng</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="eventNumber">Khách hàng</Label>
                  {/*<select style={{maxWidth: '100%', display: "block"}}
                          className="custom-select"
                          onChange={(e) => this.onSelectedCustomer(e.target.value)}
                  >
                    <option value="">Chọn khách hàng</option>
                    {this.state.listCustomer && this.state.listCustomer.map((option) => (
                      <option value={option.id} key={option.id}>{option.id}</option>
                    ))}
                  </select>*/}

                  <Select
                    options={this.state.listCustomer}
                    value={this.state.selectedCustomer[0]?.id}
                    onChange={this.onSelectedCustomer}
                    onInputChange={this.handleInputChange}
                    isSearchable
                    autosize={false}/>
                </FormGroup>
                <FormGroup>
                  <Label for="name">Tên Khách Hàng (*)</Label>
                  <Input type="text" name="customerName" value={createPickup.customerName} id="customerName" placeholder="Tên khách hàng" onChange={this.onCreatePickupChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="content">Địa chỉ (*)</Label>
                  <Input type="text" name="customerAddress" value={createPickup.customerAddress} id="customerAddress" placeholder="address" onChange={this.onCreatePickupChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="price">Phone (*)</Label>
                  <Input name="customerPhone" id="customerPhone" value={createPickup.customerPhone}  placeholder="phone" onChange={this.onCreatePickupChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="eventNumber">Số kiện (*)</Label>
                  <Input type="text" name="packs" id="packs" placeholder="Số kiện" onChange={this.onCreatePickupChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="eventNumber">Loại xe</Label>
                  <Input id="verhicle" name="verhicle" type="select" onChange={this.onCreatePickupChange} >
                    <option value="Xe máy">Xe máy</option>
                    <option value="Xe tải">Xe tải</option>
                  </Input>
                </FormGroup>
                <FormGroup className="">
                  <Label for="eventNumber" style={{marginRight: '15px'}}>Thời gian Pickup</Label>
                  <DateTimePicker onChange={this.handleDatetoSelect} value={this.state.date} />
                </FormGroup>
                <FormGroup>
                  <Label for="weight">Cân nặng (*)</Label>
                  <Input type="number" name="weight" id="weight" placeholder="Cân nặng" onChange={this.onCreatePickupChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="information">Thông tin hàng</Label>
                  <Input type="text" name="info" id="info" placeholder="Thông tin hàng" onChange={this.onCreatePickupChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="service">Dịch vụ (*)</Label>
                  {/*<Input type="text" name="service" id="service" placeholder="Dịch vụ" onChange={this.onCreatePickupChange} />*/}
                  <Input
                    placeholder="Dịch vụ"
                    name="service"
                    type="select"
                    id="service"
                    //value={this.state.billInfo.logisticService}
                    onChange={(e) => this.onCreatePickupChange(e)}
                    list="logisticServiceList"
                  >
                    <option value="" defaultValue> -- Chọn dịch vụ -- </option>
                    {this.state.listServices?.map(i => (<option value={i.name}> {i.name} </option>))}
                  </Input>
                </FormGroup>
                {/*<FormGroup>*/}
                {/*  <Label for="typeGood">Nhóm hàng (*)</Label>*/}
                {/*  <Input type="text" name="group" id="group" placeholder="Nhóm hàng" onChange={this.onCreatePickupChange} />*/}
                {/*</FormGroup>*/}
                <FormGroup>
                  <Label for="typeGood">Nhóm hàng</Label>
                  <Select
                    defaultValue={[]}
                    isMulti
                    name="colors"
                    options={groupTypeOption2}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.handleChangeType(e)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="location">Khu vực</Label>
                  <Input id="location" name="location" type="select" onChange={this.onCreatePickupChange} >
                    {Object.values(this.state.locationOption2).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="statusType">Trạng thái</Label>
                  <Input id="statusType" name="statusType" type="select" onChange={this.onCreatePickupChange} >
                    {Object.values(statusTypeOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="note">Ghi chú</Label>
                  <textarea
                    className="form-control"
                    rows="4"
                    id="note"
                    placeholder="Ghi chú"
                    onChange={this.onCreatePickupChange}
                    name="note"
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary" onClick={(e) => this.onCreatePickup(e)} >Tạo mới</Button>{' '}
                <Button color="secondary" onClick={this.toggle}>Hủy</Button>
              </ModalFooter>
            </Form>
          </Modal>

          {/* Toggle Form Edit Pickup */}
          <Modal isOpen={this.state.modalEditToggle} toggle={this.openEditModal} >
            <Form>
              <ModalHeader toggle={this.openEditModal}>Edit Thông Tin Hàng</ModalHeader>
              <ModalBody>
                {
                  this.checkPermission2() && (
                      <div>
                        <FormGroup>
                          <Label for="eventNumber">Khách hàng</Label>
                          <Select
                              options={this.state.listCustomer}
                              value={this.state.selectedCustomer[0]?.id}
                              onChange={this.onSelectedEditCustomer}
                              autosize={false}/>
                        </FormGroup>
                        <FormGroup>
                          <Label for="name">Tên Khách Hàng</Label>
                          <Input type="text" name="customerName" value={editPickup.customerName} id="customerName" placeholder="Tên khách hàng" onChange={this.onEditPickupChange}/>
                        </FormGroup>
                        <FormGroup>
                          <Label for="content">Địa chỉ</Label>
                          <Input type="text" name="customerAddress" value={editPickup.customerAddress} id="customerAddress" placeholder="address" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="price">Phone</Label>
                          <Input name="customerPhone" id="customerPhone" value={editPickup.customerPhone}  placeholder="phone" onChange={this.onEditPickupChange}/>
                        </FormGroup>
                        {/*<FormGroup className="">*/}
                        {/*  <Label for="eventNumber" style={{marginRight: '15px'}}>Ngày giờ lấy hàng</Label>*/}
                        {/*  <DateTimePicker onChange={this.handleDatetoSelect} value={this.state.date} />*/}
                        {/*</FormGroup>*/}
                        <FormGroup>
                          <Label for="eventNumber">Số kiện</Label>
                          <Input type="number" name="packs" id="packs" value={editPickup.packs} placeholder="Số kiện" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="eventNumber">Loại xe</Label>
                          <Input id="verhicle" name="verhicle"  value={editPickup.verhicle} type="select" onChange={this.onEditPickupChange} >
                            <option value="Xe máy">Xe máy</option>
                            <option value="Xe tải">Xe tải</option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label for="weight">Cân nặng</Label>
                          <Input type="number" name="weight" id="weight"  value={editPickup.weight} placeholder="Cân nặng" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="information">Thông tin hàng</Label>
                          <Input type="text" name="info" id="info" value={editPickup.info}  placeholder="Thông tin hàng" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="service">Dịch vụ</Label>
                          <Input type="text" name="service" id="service" value={editPickup.service}  placeholder="Dịch vụ" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="service">Dịch vụ (*)</Label>
                          {/*<Input type="text" name="service" id="service" placeholder="Dịch vụ" onChange={this.onCreatePickupChange} />*/}
                          <Input
                            placeholder="Dịch vụ"
                            name="service"
                            type="select"
                            id="service"
                            value={editPickup.service}
                            onChange={(e) => this.onEditPickupChange(e)}
                            list="logisticServiceList"
                          >
                            <option value="" defaultValue> -- Chọn dịch vụ -- </option>
                            {this.state.listServices?.map(i => (<option value={i.name}> {i.name} </option>))}
                          </Input>
                        </FormGroup>
                        {/*<FormGroup>*/}
                        {/*  <Label for="typeGood">Nhóm hàng</Label>*/}
                        {/*  <Input type="text" name="group" id="group" value={editPickup.group} placeholder="Nhóm hàng" onChange={this.onEditPickupChange} />*/}
                        {/*</FormGroup>*/}
                        <FormGroup>
                          <Label for="typeGood">Nhóm hàng</Label>
                          <Select
                            defaultValue={[]}
                            isMulti
                            name="colors"
                            options={groupTypeOption2}
                            value={this.state.groupSelected}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => this.handleChangeType(e)}
                          />
                        </FormGroup>
                        {/*<FormGroup>*/}
                        {/*  <Label for="typeGood">Nhân viên nhận hàng</Label>*/}
                        {/*  <Input type="text" name="status" id="status" value={editPickup.status} placeholder="Nhân viên nhận hàng" onChange={this.onEditPickupChange} />*/}
                        {/*</FormGroup>*/}
                        <FormGroup>
                          <Label for="location">Khu vực</Label>
                          <Input id="location" name="location" value={editPickup.location} type="select" onChange={this.onEditPickupChange} >
                            {Object.values(locationOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label for="weight">NVGN</Label>
                          <Input type="text" name="nvgn" id="nvgn"  value={editPickup.nvgn} placeholder="" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="price">Hình ảnh</Label>
                          {
                            (this.state.imagesEdit && this.state.imagesEdit.length) ? (
                              <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                                {this.state.imagesEdit.map((image, index) => (
                                  <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                                    <a href={`/pickup/${image}`} target="_blank"> <img src={`/pickup/${image}`} alt="" width="100" /></a>
                                    <div className="image-item__btn-wrapper" style={{
                                      marginTop: '8px', position: 'absolute', right: 0,
                                      top: 0
                                    }}>
                                      <a onClick={() => this.onImageEditRemove(index)}>
                                        <img src="/close.png" alt="" width="10"/>
                                      </a>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : ''
                          }


                          <ImageUploading
                            multiple
                            value={this.state.images}
                            onChange={this.onChange2}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                          >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                              }) => (
                              // write your building UI
                              <div className="upload__image-wrapper"
                                   style={{marginTop: '30px'}}>
                                <a className="btn btn-primary"
                                   style={isDragging ? {color: 'red'} : undefined}
                                   onClick={onImageUpload}
                                   {...dragProps}
                                >
                                  Chọn thêm hình ảnh
                                </a>
                                &nbsp;&nbsp;&nbsp;
                                {
                                  (this.state.images && this.state.images.length) ? (
                                    <a onClick={onImageRemoveAll} className="btn btn-danger">Remove all images</a>
                                  ) : ''
                                }
                                <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                                  {this.state.images.map((image, index) => (
                                    <div key={index} className="image-item" style={{marginTop: '10px'}}>
                                      <img src={image['data_url']} alt="" width="100"/>
                                      <div className="image-item__btn-wrapper" style={{marginTop: '8px'}}>
                                        <a onClick={() => onImageRemove(index)}>
                                          <img src="/close.png" alt="" width="10"/>
                                        </a>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </ImageUploading>
                        </FormGroup>
                      </div>
                    )
                }
                <FormGroup>
                  <Label for="statusType">Trạng thái</Label>
                  <Input id="statusType" name="statusType" type="select" value={editPickup.statusType} onChange={this.onEditPickupChange} >
                    {Object.values(statusTypeOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="note">Ghi chú</Label>
                  <textarea
                    className="form-control"
                    rows="4"
                    id="note"
                    value={editPickup.note}
                    placeholder="Ghi chú"
                    onChange={this.onEditPickupChange}
                    name="note"
                  />
                </FormGroup>
                {
                  editPickup.statusType === 'Thất bại' && (
                        <FormGroup>
                          <Label for="note2">Ghi chú thất bại</Label>
                          <textarea
                              className="form-control"
                              rows="4"
                              id="note2"
                              value={editPickup.note2}
                              onChange={this.onEditPickupChange}
                              name="note2"
                          />
                        </FormGroup>
                    )
                }
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onEditPickup(e)}>Update</Button>{' '}
                <Button color="secondary" onClick={this.openEditModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={this.state.modalEditStatusToggle} toggle={this.openEditStatusModal} >
            <Form>
              <ModalHeader toggle={this.openEditStatusModal}>Cập nhật trạng thái giao hàng</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="statusType">Trạng thái</Label>
                  <Input id="statusType" name="statusType" type="select" value={editPickup.statusType} onChange={this.onEditPickupChange} >
                    {Object.values(statusTypeOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Hình ảnh</Label>

                  {
                    (this.state.imagesEdit && this.state.imagesEdit.length) ? (
                      <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                        {this.state.imagesEdit.map((image, index) => (
                          <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                            <img src={`/pickup/${image}`} alt="" width="100" />
                            <div className="image-item__btn-wrapper" style={{marginTop: '8px', position: 'absolute', right: 0,
                              top: 0}}>
                              <a onClick={() => this.onImageEditRemove(index)}>
                                <img src="/close.png" alt="" width="10" />
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : ''
                  }

                  <ImageUploading
                    multiple
                    value={this.state.images}
                    onChange={this.onChange2}
                    maxNumber={12}
                    dataURLKey="data_url"
                  >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <a className="btn btn-primary"
                           style={isDragging ? { color: 'red' } : undefined}
                           onClick={onImageUpload}
                           {...dragProps}
                        >
                          Chọn hình ảnh
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        {
                          (this.state.images && this.state.images.length) ? (
                            <a onClick={onImageRemoveAll} className="btn btn-danger">Remove all images</a>
                          ) : ''
                        }
                        <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                          {this.state.images.map((image, index) => (
                            <div key={index} className="image-item" style={{marginTop: '10px'}}>
                              <img src={image['data_url']} alt="" width="100" />
                              <div className="image-item__btn-wrapper" style={{marginTop: '8px'}}>
                                <a onClick={() => onImageRemove(index)}>
                                  <img src="/close.png" alt="" width="10" />
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onEditPickup(e)}>Update</Button>{' '}
                <Button color="secondary" onClick={this.openEditModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

          <Modal
            isOpen={this.state.modalPrintPackagesToggle}
            scrollable
            centered
            size='lg'
            fullscreen="true |xxl-down'"
            toggle={this.printPackagesToggle}
          >
            <ModalHeader toggle={this.printPackagesToggle}>
              Print Pickup
            </ModalHeader>
            <ModalBody>
              <style> {printPickup()} </style>
              <div>
                {
                  this.isRingoPost() ? (
                    <PrintPickup
                      billData={this.state.printData}
                      ref={(el) => (this.componentRef = el)}
                    />
                  ) : (
                    <RingoPrintPickup
                      billData={this.state.printData}
                      ref={(el) => (this.componentRef = el)}
                    />
                  )
                }

              </div>
            </ModalBody>
            <ModalFooter>
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button href='#'> Print this out!</Button>
                  );
                }}
                content={() => this.componentRef}
              />
            </ModalFooter>
          </Modal>

        </Row>
      </div>
    )
  }
}
export default Pickup
