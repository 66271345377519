import logo from '../../images/hopdong/logo-tp.png';
import barcode from '../../images/hopdong/barcode.png';
import React from 'react';
import {fetchUserByID} from '../../actions/user'
import {Row} from 'reactstrap';
import {DOC_TYPE} from '../../constants';
import s from './Packet.module.scss';
import Rounding from '../../utils/rounding';
import Barcode from 'react-jsbarcode'
import axios from "axios";

// Header Component
const BillHeader = ({logoUrl, kgCode, defaultLogo}) => (
    <>
        <tr className="text-center">
            <td rowSpan="2" style={{width: '30%'}} className="text-center">
                <img 
                    style={{
                        margin: "0 auto",
                        display: "block",
                        objectFit: "contain",
                        maxHeight: "96px"
                    }} 
                    src={logoUrl} 
                    onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop
                        e.target.src = defaultLogo;
                    }}
                    className="img-fluid" 
                    alt="Logo"
                />
            </td>
            <td colSpan="2" className="text-center">
                <h4 className="text-danger text-uppercase">
                    <strong>Tinphat Express</strong>
                </h4>
            </td>
            <td colSpan="3">
                <Barcode 
                    style={{width: '150px', height: 'auto'}} 
                    options={{displayValue: false}}
                    renderer="image" 
                    value={kgCode}
                />
            </td>
        </tr>
        <tr className="text-center">
            <td padding="5px" colSpan="2">Tổng đài: 1900 4642</td>
            <td colSpan="3">Air waybill : <h3><strong>{kgCode}</strong></h3></td>
        </tr>
    </>
);

// Contact Info Component
const ContactInfo = ({title, data}) => (
    <div className="mb-1">
        <span className="font-weight-bold">{title}: &nbsp;</span>
        <span className={data.isUpperCase ? "text-uppercase" : ""}>{data.value}</span>
    </div>
);

// Address Section Component
const AddressSection = ({data, type}) => (
    <div className="row">
        <div className="col-6">
            <div className="d-flex flex-column">
                <ContactInfo title="Contact Name" data={{value: data[`${type}Name`], isUpperCase: true}} />
                <ContactInfo title="Phone/Fax No" data={{value: data[`${type}Phone`]}} />
                <ContactInfo title="Company Name" data={{value: data[`${type}CompanyName`], isUpperCase: true}} />
            </div>
        </div>
        <div className="col-6">
            <div className="d-flex flex-column">
                <ContactInfo title="Address" data={{value: type === 'sender' ? data.senderAddress : 
                    [data.receiverAddr1, data.receiverAddr2, data.receiverAddr3].join(' ')}} />
                <ContactInfo title={type === 'sender' ? 'City' : 'City/Town'} 
                    data={{value: type === 'sender' ? data.city : 
                        `${data.receiverCity} - ${data.receiverProvince}`}} />
                <ContactInfo title="Country" data={{value: type === 'sender' ? data.country : data.receiverCountry, isUpperCase: true}} />
                {type === 'sender' ? 
                    <ContactInfo title="Email" data={{value: data.email}} /> :
                    <ContactInfo title="Postal Code" data={{value: data.receiverPostalCode}} />
                }
            </div>
        </div>
    </div>
);

// Package Dimensions Component
const PackageDimensions = ({packs}) => (
    <>
        {[0, 1, 2].map(index => (
            <tr key={index} className="text-center" height={index === 0 ? "25px" : "30px"}>
                <td className="text-center">{(packs.length > index) && packs[index].quantity}</td>
                <td>{(packs.length > index) && packs[index].length}</td>
                <td>{(packs.length > index) && packs[index].width}</td>
                <td>{(packs.length > index) && packs[index].height}</td>
            </tr>
        ))}
    </>
);

class BillToPrint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billCreator: {},
            logoUrl: logo,
            billupCreator: {}
        }
    }

    async componentDidMount() {
        this.setState({billCreator: await fetchUserByID(this.props.billData.companyUserID)})
        try {
            const {data: user} = await axios.get(`/user-by-name/${this.props.billData.owner}`)
            this.setState({billupCreator: user})
            if (user?.role === 'ops') {
                const customLogoUrl = `/kango_logos/${this.props.billData.companyUserID}-logo.png`;
                this.setState({logoUrl: customLogoUrl})
            }
        } catch (error) {
            console.log(error)
        }
    }

    calWeight(packs) {
        return packs.reduce(function (a, b) {
            return a + parseFloat(b["weight"]) * parseFloat(b["quantity"])
        }, 0) || ""
    }

    render() {
        var packs = JSON.parse(this.props.billData.packs)

        const billToPrint = <table id="billprinted" width="100%" bordered="true" style={{"fontSize": "100%"}}>

            <tbody cellPadding="" cellSpacing="0">
            <BillHeader 
                logoUrl={this.state.logoUrl} 
                kgCode={this.props.billData.kgCode}
                defaultLogo={logo}
            />

            <tr className="text-center">
                <td colSpan="3" style={{width: '50%'}}> Website: https://tinphatexpress.vn/</td>
                <td colSpan="3" style={{width: '50%'}}> 
                    <span className="h4 font-weight-bold">Service: {this.props.billData.logisticService}</span> 
                </td>
            </tr>

            <tr className="b-title">
                <td colSpan="6" className="table-warning" style={{padding: "5px 10px"}}>
                    <div><strong> 1. From <i>(Shipper) </i>:</strong></div>
                </td>
            </tr>
            <tr height="30px">
                <td rowSpan="2" colSpan="3">
                    <AddressSection data={this.props.billData} type="sender" />
                </td>
                <td className="b-title text-center table-warning font-weight-bold" width="120px" style={{padding: "5px 10px"}}>
                    <div className="table-warning">Total number of Packages</div>
                </td>
                <td className="b-title text-center table-warning font-weight-bold" width="120px" style={{padding: "5px 10px"}}>
                    <div className="table-warning">Gross Weight</div>
                </td>
                <td className="b-title text-center table-warning font-weight-bold" width="120px" style={{padding: "5px 10px"}}>
                    <div className="table-warning">Chargeable Weight</div>
                </td>
            </tr>
            <tr className="text-center">
                <td> {(this.props.billData.packageType === DOC_TYPE) ? this.props.billData.docCount : packs.reduce(function (a, b) {
                    return a + parseFloat(b["quantity"])
                }, 0) || ""}</td>
                <td> {(this.props.billData.packageType === DOC_TYPE) ? this.props.billData.docWeight : this.calWeight(packs)}</td>
                <td> {(this.props.billData.packageType === DOC_TYPE) ? this.props.billData.docWeight : Rounding(packs.reduce(function (a, b) {
                    return a + parseFloat(b["chargedWeight"])
                }, 0)) || ""}</td>
            </tr>

            <tr className="b-title">
                <td colSpan="6" className="table-warning" style={{padding: "5px 10px"}}>
                    <div><strong> 2. To <i>(Receiver)</i>: </strong></div>
                </td>
            </tr>
            <tr>
                <td rowSpan="4" colSpan="2">
                    <AddressSection data={this.props.billData} type="receiver" />
                </td>
                <td className={"font-weight-bold " + s.crossed} width="125px"
                    height="30px"> &emsp; &emsp;&emsp;&nbsp;&nbsp;Dimensions<br/>Pieces
                </td>
                <td className="text-center font-weight-bold"> L (cm)</td>
                <td className="text-center font-weight-bold"> W (cm)</td>
                <td className="text-center font-weight-bold"> H (cm)</td>
            </tr>

            <PackageDimensions packs={packs} />

            <tr className="b-title">
                <td colSpan="6" className="table-warning" style={{padding: "5px 10px"}}>
                    <div><strong> 3. Thông tin Đơn hàng <i>(Shipment Information)</i></strong></div>
                </td>
            </tr>
            <tr>
                <td colSpan="6" style={{padding: "5px 10px"}}>
                    <div>Mô tả hàng hóa : (Description of goods):
                        <strong> {(this.props.billData.packageType === DOC_TYPE) ? "Documents" : this.props.billData.packContent} </strong>
                    </div>
                </td>
            </tr>
            <tr height="30px">
                <td colSpan="6">
                    Giá trị (value invoice): <strong>{this.props.billData.packInvoiceValue}</strong> &emsp;
                    {this.props.billData.packInvoiceUnit}
                </td>
            </tr>
            <tr>
                <td className="font-weight-bold"> Chữ ký người gửi (Shipper Signature) <br/>
                    Ngày, giờ (Date /time):
                </td>
                <td> {new Date(this.props.billData.createdAt).toLocaleDateString()} </td>
                <td colSpan="3" className="font-weight-bold"> Nhân viên nhận hàng (Picked up by) <br/>
                    Ngày, giờ (Date /time):
                </td>
                <td colSpan="1"> {new Date(this.props.billData.createdAt).toLocaleDateString()}</td>
            </tr>
            <tr height="50px">
                <td colSpan="2">
                </td>
                <td colSpan="4">
                </td>
            </tr>

            </tbody>

        </table>

        return (
            <div>
                {[1, 2, 3].map((_, index) => (
                    <React.Fragment key={index}>
                        <div style={{pageBreakAfter: 'always'}}>{billToPrint}</div>
                        {index < 2 && <br/>}
                    </React.Fragment>
                ))}
            </div>
        );
    }
}

export default BillToPrint;
