import logo from '../../images/hopdong/logo-tp.png';
import barcode from '../../images/hopdong/barcode.png';
import React from 'react';
import {fetchUserByID} from '../../actions/user'
import {USER_ROLE, DOC_TYPE, PACK_TYPE} from '../../constants';
import s from './Packet.module.scss';
import "./pickup.scss";
import {Table, Row, Col, Container} from 'reactstrap';
import {Textfit} from 'react-textfit';
import Barcode from 'react-jsbarcode'
import moment from "moment/moment";
import axios from "axios";
import QRCode from "react-qr-code";

class PrintPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLogo: false,
      billCreator: {},
      logoUrl: logo
    }
  }

  async componentDidMount() {
    this.setState({billCreator: await fetchUserByID(this.props.billData.companyUserID)})
    try {
      const {data: user} = await axios.get(`/user-by-name/${this.props.billData.owner}`)
      console.log(user)
      this.setState({billupCreator: user})
      if (user?.role === 'ops') {
        this.setState({logoUrl: "/kango_logos/" + user.id + "-logo.png"})
      }
    } catch (error) {
      console.log(error)
    }
  }

  formatDate = (str) => {
    return moment(str).format('DD/MM/YYYY')
  }

  getHawbCode = (pkg, index) => {
    try {
      let hawbCode = ``
      index = index - 1
      if (pkg.hawbCode) {
        let hawbArr = pkg.hawbCode.trim().split(',')
        if (hawbArr.length >= index) {
          hawbCode = hawbArr[index]
        }
      }
      return hawbCode?.trim() || pkg.kgCode
    } catch (e) {
      return ''
    }
  }

  formatCountry = (country) => {
    let countryArr = country.split('-')
    return countryArr[0]
  }

  render() {

    const {billData = {}} = this.props;
    let totalPacks = 0
    if (this.props.billData.packageType === PACK_TYPE) {
      var packs = JSON.parse(this.props.billData.packs)
      totalPacks = packs.reduce(function (a, b) {
        return a + parseInt(b['quantity'])
      }, 0)
    } else {
      totalPacks = this.props.billData.docCount
    }

    let barcodeValue = this.props.billData.referenceCode ? this.props.billData.referenceCode : this.props.billData.kgCode
    let barcodeArr = []
    if (this.props.billData.hawbCode) {
      barcodeValue = this.props.billData.hawbCode
      barcodeValue = barcodeValue ? barcodeValue.replace(/\s/g, "") : '';

      // check barcodeValue has , and split to array
      if (barcodeValue.includes(',')) {
        barcodeArr = barcodeValue.split(',')
      }
    }
    barcodeValue = barcodeValue.replace(/\s/g, "");


    const shippingMark = (idx, total) => {
      return <div style={{pageBreakAfter: 'always'}}>
        <div className="sm-wrapper print-sm-wrapper">
          <div className="sm-print-header">
            <div className="sm-header-logo">
              <img src={this.state.logoUrl} alt="logo"/>
            </div>
            <div className="sm-header-info text-center">
              <div className="sm-title" style={{fontSize: '16px'}}><strong>TÍN PHÁT EXPRESS</strong></div>
              <div className="sm-phone">1900 4642</div>
              <div className="sm-address">42/43-45 Nguyễn Minh Hoàng, P12, Tân Bình, HCM</div>
            </div>
          </div>
          <div className="sm-print-body" style={{marginTop:'15px'}}>
            <div className="sm-body-info d-flex">
              <div className="sm-info" style={{width: '63%', flex: '1 0 auto', 'paddingRight': '20px'}}>
                <div className="sm-info-title" style={{fontSize: '16px'}}><strong>Deliver to:</strong></div>
                <div className="sm-info-content">
                  <div className="sm-info-name" style={{fontSize: '18px'}}><strong>{billData?.receiverName}</strong></div>
                  <div><strong>Phone/Fax No:</strong> {billData?.receiverPhone}</div>
                  <div><strong>Address:</strong> {billData.receiverAddr1}, {billData.receiverCity}, {billData.receiverProvince},  {billData.receiverPostalCode}, {billData.receiverCountry}</div>
                </div>
              </div>
              <div className="sm-qr-code d-flex">
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={this.getHawbCode(billData, idx)}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
          </div>
          <div className="sm-center-info d-flex justify-content-between align-items-center" style={{border: "3px solid #000", padding: '5px 10px'}}>
            <div className="sm-info-title" style={{fontSize: '16px'}}>Destination: <strong> { this.formatCountry(billData.receiverCountry) }</strong></div>
            <div className="sm-info-title" style={{fontSize: '16px'}}>Service: <strong> { billData.logisticService }</strong></div>
          </div>
          <div className="sm-print-tpcode" style={{borderBottom: '3px #000 solid'}}>
            <div className="sm-info-title text-center" style={{fontSize: '30px'}}><strong>{ billData.kgCode }</strong></div>
          </div>
          <div className="sm-print-footer text-center" style={{padding: '10px 0 '}}>
            {billData.referenceCode && (
                <div className="sm-footer-awb">
                  Awb code: { billData.referenceCode || '' }
                </div>
            )}

            <div className="sm-footer-hawb" style={{fontSize: '20px'}}>
              <strong>Hawb code: {this.getHawbCode(billData, idx)} </strong>
            </div>
            <Barcode renderer="image" style={{height: '50px', width: '90%'}} options={{displayValue: false}}
                     value={this.getHawbCode(billData, idx)}/>
          </div>
          <div className="sm-print-footer">
            <div className="d-flex justify-content-between">
              <div>Date: <strong>{this.formatDate(billData.date)}</strong></div>
              <div><strong>PARCEL: {idx} of {total}</strong></div>
            </div>
            <div style={{fontSize: '18px', textAlign: 'center'}}>Delivery by TIN PHAT EXPRESS</div>
          </div>
        </div>
      </div>
    }
    return (
      <div>
        {[...Array(totalPacks)].map((pkg, i) => shippingMark(i + 1, totalPacks))}
      </div>
    );
  }
}

export default PrintPackages;
