import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  InputGroup,
  FormGroup,
  Label,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormText,
} from 'reactstrap';
import s from "./Packet.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ReactToPrint from "react-to-print";
import {confirmAlert} from "react-confirm-alert";

import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Link} from 'react-router-dom'
import BillToPrint from "./BillToPrint";
import InvoiceToPrint from "./InvoiceToPrint";
import PrintPackages from "./PrintPackages";
import PrintSM from "./PrintSM";
import PrintSMOPS from "./PrintSMOPS";
import fileDownload from 'js-file-download';
import {
  fetchPackages,
  updatePackage,
  deletePackage,
} from "../../actions/package";
import {DOC_TYPE, USER_ROLE} from "../../constants";
import jwt from "jsonwebtoken";
import Rounding from "../../utils/rounding";
import PickupListTable from "./pickupListTable/PickupListTable";
import axios from 'axios'
import {createPickup, fetchPickup, deletePickup, updatePickup} from "../../actions/pickup";
import {DateRangePickerCalendar, START_DATE} from "react-nice-dates";
import PrintBillup from "./PrintBillup";
import RingoPrintBillup from "./RingoPrintBillup";
import EditPacketTitle from '../../images/edit-packet-title.png'
import moment from "moment";

const FilterBillCode = "billCode";
const FilterReceiverCompanyName = "receiverCompanyName";
const FilterSenderCompanyName = "senderCompanyName";
const FilterReceiverAddr = "receiverAddr";
const FilterReferenceCode = "referenceCode";
const FilterLogisticCode = "logisticCode";
const FilterService = "logisticService";
const FilterHawb = "hawbCode";
const FIRST_PAGE = 1;
const INIT_LIMIT = 20;

const fileReader = new FileReader();

class Packet extends React.Component {
  constructor(props) {
    super(props);

    this.filterCriteriaRef = React.createRef();
    this.filterValueRef = React.createRef();

    this.state = {
      currUser: {},
      disableDownloadButton: false,
      fromdate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7,
        new Date().getHours(),
        new Date().getMinutes()
      ), // get last 7 days
      todate: new Date(),
      modalToggle: false,
      modalPrintPackagesToggle: false,
      modalPrintSMToggle: false,
      printId: '',
      printData: '',
      modalInvToggle: false,
      modalSMToggle: false,
      printInvData: '',
      printSMData: '',
      pkRole: 'admin',
      file: null,

      filterLimit: INIT_LIMIT,
      filterPage: FIRST_PAGE,

      modalToggleUpdatePackage: false,
      modalTogglePickup: false,
      modalUpload: false,
      pickupStatus: 1,
      currentPage: 1,
      updatingPackage: {},
      oldStatus: "",
      pickup: {
        type: 'Xe tải',
        address: '',
        packageId: '',
        remark: '',
        datetime: '',
        status: 'printed',
        grossWeight: ''
      },
      listPickup: [],
      keySearch: '',
      locations: [],
      searchStatusType1: '',
      searchReportStatus: '',
      searchStatus: '',
      searchBranch: ''
    };
    this.handleDatefromSelect = this.handleDatefromSelect.bind(this);
    this.handleDatetoSelect = this.handleDatetoSelect.bind(this);
    this.printToggle = this.printToggle.bind(this);
    this.printPackagesToggle = this.printPackagesToggle.bind(this);
    this.toggleUpdatePackage = this.toggleUpdatePackage.bind(this);
    this.modalUploadToggle = this.modalUploadToggle.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onClickPrint = this.onClickPrint.bind(this);
    this.onClickPrintInv = this.onClickPrintInv.bind(this);
    this.onClickPrintPackages = this.onClickPrintPackages.bind(this);
    this.onClickPrintSM = this.onClickPrintSM.bind(this);
    this.printInvToggle = this.printInvToggle.bind(this);
    this.printSMToggle = this.printSMToggle.bind(this);
    this.onUpdatePackage = this.onUpdatePackage.bind(this);
    this.onChangeUpdatePackage = this.onChangeUpdatePackage.bind(this);
    this.onDeletePackage = this.onDeletePackage.bind(this);
    this.onChangeLimit = this.onChangeLimit.bind(this);
    this.toggleShowPickupModal = this.toggleShowPickupModal.bind(this);
    this.togglePickupStatus = this.togglePickupStatus.bind(this);
    this.onCreatePickupChange = this.onCreatePickupChange.bind(this);
    this.handleDatePickupSelect = this.handleDatePickupSelect.bind(this);
    this.deletePickup = this.deletePickup.bind(this);
    this.updatePickup = this.updatePickup.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.drtControl = this.drtControl.bind(this);
    this.checkCanEdit = this.checkCanEdit.bind(this);
    this.onChangeReportStatus = this.onChangeReportStatus.bind(this);
    this.onChangeStatusType1 = this.onChangeStatusType1.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeBranch = this.onChangeBranch.bind(this);
  }

  handleDatefromSelect(date) {
    this.setState({fromdate: date});
  }

  handleDatetoSelect(date) {
    this.setState({todate: date});
  }

  handleDatePickupSelect(date) {
    let createPickupInfo = this.state.pickup
    createPickupInfo.datetime = date
    this.setState({pickup: createPickupInfo});
  }

  onCreatePickupChange(e) {
    const {name, value} = e.target;
    let createPickupInfo = this.state.pickup
    createPickupInfo[name] = value
    this.setState({pickup: createPickupInfo})
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    packages: PropTypes.any, // eslint-disable-line
    totalPackages: PropTypes.number,
    isFetching: PropTypes.bool,
    isFetchingPickup: PropTypes.bool,
    pickup: PropTypes.any,
    totalPickup: PropTypes.number,

  };

  static defaultProps = {
    isFetching: false,
    packages: [],
    totalPackages: 0,
    pickup: []
  };

  toggleUpdatePackage(e, pack) {
    if (pack) {
      let copyOfPack = Object.assign({}, pack);
      this.setState({updatingPackage: copyOfPack, oldStatus: copyOfPack.status});
    }

    this.setState((prevState, props) => ({
      modalToggleUpdatePackage: !prevState.modalToggleUpdatePackage,
    }));
  }

  toggleShowPickupModal() {
    this.setState((prevState) => ({
      modalTogglePickup: !prevState.modalTogglePickup,
    }));
  }

  getOwnerRole = async (pkg) => {
    try {
      if (pkg.owner) {
        // axios call to get user by name
        const {data: user} = await axios.get(`/user-by-name/${pkg.owner}`)
        if (user && user.role) {
          return user.role
        }
      }
    } catch (e) {
      return 'admin'
    }
  }

  modalUploadToggle() {
    this.setState((prevState) => ({
      modalUpload: !prevState.modalUpload,
    }));
  }

  onChooseFile(e) {
    this.setState({file: e.target.files[0]})
  }

  CSVstring_to_Array(fileContent, delimiter = ',') {
    const rows = fileContent.split('\n');
    const headers = rows[0].split(',');
    const headerArr = [];

    for (let i = 0; i < headers.length; i += 2) {
      const obj = {
        hawbCode: headers[i],
        tracking: headers[i + 1]
      };

      headerArr.push(obj);
    }

    let data = []
    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(';');
      data.push({
        hawbCode: row[0],
        trackingCode: this.cleanTrackingCode(row[1])
      })
    }
    return data;
  };

  cleanTrackingCode = (trackingCode) => {
    trackingCode = trackingCode?.toString()?.replace(/\./g, "").replace(/\r/g, "")
    trackingCode = trackingCode?.replace(/\t/g, "").replace(/\n/g, "")
    return trackingCode
  }


  async uploadTrackingCSV() {
    const {file} = this.state
    let self = this
    if (!file) {
      toast.error('Vui lòng chọn file để upload')
      return
    }

    fileReader.readAsText(file);
    fileReader.onload = async function (event) {
      const csvOutput = event.target.result;
      let arr = self.CSVstring_to_Array(csvOutput)
      try {
        await axios.put('/upload-tracking', {
          trackingCodes: arr
        })
        toast.success('Upload tracking thành công')
        await self.onSearch()
      } catch (e) {
        toast.error('Upload tracking thất bại')
        console.error(e)
      }
    };
  }

  async onChangeReportStatus(e) {
    await this.setState({searchReportStatus: e.target.value})
    await this.onSearch()
  }

  async onChangeBranch(e) {
    await this.setState({searchBranch: e.target.value})
    await this.onSearch()
  }

  async onChangeStatusType1(e) {
    await this.setState({searchStatusType1: e.target.value})
    await this.onSearch()
  }

  async onChangeStatus(e) {
    await this.setState({searchStatus: e.target.value})
    await this.onSearch()
  }


  togglePickupStatus(status) {
    this.setState({
      pickupStatus: status,
    });
  }

  onUpdatePackage(e) {
    this.toggleUpdatePackage();

    confirmAlert({
      title: 'Xác nhận Cập nhật',
      message: 'Bạn có chắc muốn cập nhật thông tin package này?',
      buttons: [
        {
          label: 'Có',
          onClick: async () => {
            this.props.dispatch(updatePackage(this.state.updatingPackage));
            if (this.state.oldStatus !== this.state.updatingPackage.status && this.state.updatingPackage.status === "Sắp giao") {
              try {
                const {data: packageInfo} = await axios.get(`/get-packageinfo/${this.state.updatingPackage.kgCode}`)
                const {data: customer} = await axios.get(`/get-customer/${packageInfo?.customerId}`)
                const {data: user} = await axios.get(`/user-by-name/${this.state.updatingPackage.owner}`)
                if (user && (user.role !== 'ops')) {
                  axios.post(`/send-gapo`, {
                    requestId: this.state.updatingPackage.kgCode,
                    senderPhone: customer.phone,
                    receiverPhone: this.state.updatingPackage.receiverPhone,
                    ngayTao: moment(this.state.updatingPackage.createdAt).format('DD/MM/YYYY'),
                    tenkh: this.state.updatingPackage.receiveName,
                    type: "receive"
                  })
                }
              } catch (e) {
                console.log(e)
              }

            }
          },
        },
        {
          label: 'Không',
          onClick: () => {
          },
        },
      ],
    });
  }

  async getBillupById(kgCode) {
    if (kgCode) {
      try {
        const {data} = await axios.post(`/get-billup/${kgCode}`)
        return data
      } catch (e) {
        return ''
      }
    }
    return ''
  }

  handleChange(value) {
    this.setState({keySearch: value})
  }

  onDeletePackage = () => {
    this.toggleUpdatePackage();

    confirmAlert({
      title: 'Xác nhận Xoá',
      message: 'Bạn có chắc muốn xoá thông tin kiện hàng này?',
      buttons: [
        {
          label: 'Có',
          onClick: () => {
            this.props.dispatch(deletePackage(this.state.updatingPackage.id));

            // call api delete packageInfo
            try {
              axios.get(`/delete-packageinfo/${this.state.updatingPackage.kgCode}`)
            } catch (e) {

            }
          },
        },
        {
          label: 'Không',
          onClick: () => {
          },
        },
      ],
    });
  };

  onChangeUpdatePackage(e) {
    const {name, value} = e.target;
    var newInfo = this.state.updatingPackage;
    newInfo[name] = value;
    this.setState(newInfo);
  }

  handleFocusChange(newFocus) {
    const focus = newFocus ?? START_DATE
    this.setState({focus})
  }

  setStartDate(startDate) {
    this.setState({startDate})
  }

  setEndDate(endDate) {
    this.setState({endDate})
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token');
    let currUser = jwt.decode(token);
    this.setState({currUser: currUser});
    // get page from query params
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get('page') || 1;
    this.setState({currentPage: page})
    await this.props.dispatch(
      fetchPackages({
        companyUserID: (currUser.role === USER_ROLE.SALER || currUser.role === USER_ROLE.CONSULTANT || currUser.role === USER_ROLE.OPS) ? currUser.id : "",
        receiverCompanyName: "",
        owner: (currUser.role === USER_ROLE.SALER || currUser.role === USER_ROLE.CONSULTANT || currUser.role === USER_ROLE.OPS) ? currUser.displayName : "",
        senderCompanyName: "",
        receiverAddr: "",
        referenceCode: "",
        logisticCode: "",
        logisticService: "",
        billCode: "",
        limit: this.state.filterLimit,
        page: page,
        fromDate: this.state.fromdate,
        toDate: this.state.todate,
        searchReportStatus: this.state.searchReportStatus,
        searchStatusType1: this.state.searchStatusType1,
        searchStatus: this.state.searchStatus,
        searchBranch: this.state.searchBranch,
        hawbCode: '',
        customerId: ''
      })
    );

    await this.fetchPackagesLocation()

    //await this.fetchPickup()
  }

  fetchPackagesLocation = async () => {
    // get all hawbCode from packages
    const {packages} = this.props
    let hawbCodes = []
    packages.forEach((pkg) => {
      if (pkg.hawbCode) {
        let hawbArr = pkg.hawbCode.trim().split(',')
        hawbCodes = hawbCodes.concat(hawbArr)
        hawbCodes = hawbCodes.map(hawb => hawb.trim())
      }
    })

    // get all location from kgCode
    if (hawbCodes.length > 0) {
      try {
        const {data} = await axios.post('/get-packages-location', {
          hawbCodes: hawbCodes
        })
        this.setState({locations: data})
        //

      } catch (e) {
        console.log(e)
      }
    }

  }

  onClickPrint(e, pgk) {
    this.setState({printData: pgk});
    this.printToggle();
  }

  printToggle() {
    this.setState((prevState) => ({modalToggle: !prevState.modalToggle}));
  }

  printPackagesToggle() {
    this.setState((prevState) => ({
      modalPrintPackagesToggle: !prevState.modalPrintPackagesToggle,
    }));
  }

  onClickPrintInv(e, pgk) {
    this.setState({printInvData: pgk});
    this.printInvToggle();
  }

  async onClickPrintPackages(e, pgk) {
    // get customer info
    try {
      const {data: packageInfo} = await axios.get(`/get-packageinfo/${pgk.kgCode}`)
      const {data: customer} = await axios.get(`/get-customer/${packageInfo?.customerId}`)
      pgk.packageInfo = packageInfo
      pgk.customer = customer
    } catch (e) {
      console.log(e)
    }
    this.setState({printInvData: pgk});
    this.printPackagesToggle();
  }

  async onClickPrintSM(e, pgk) {
    const {data: user} = await axios.get(`/user-by-name/${pgk.owner}`)
    this.setState({printSMData: pgk, pkRole: user?.role});
    this.printSMToggle();
  }

  printInvToggle() {
    this.setState((prevState) => ({
      modalInvToggle: !prevState.modalInvToggle,
    }));
  }

  printSMToggle() {
    this.setState((prevState) => ({
      modalSMToggle: !prevState.modalSMToggle,
    }));
  }

  onChangeLimit(e) {
    this.setState({
      filterLimit: e.target.value,
    });
  }

  async onSearch(e, page) {
    const {currUser} = this.state
    // set current url ?page=${page}
    // push to router
    this.props.history.push(`/app/packet?page=${page || '1'}`)
    let filterParams = {
      companyUserID:
        (this.state.currUser.role === USER_ROLE.CUSTOMER || this.state.currUser.role === USER_ROLE.SALER)
          ? this.state.currUser.id
          : '',
      owner: (currUser.role === USER_ROLE.SALER || currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) ? currUser.displayName : "",
      limit: this.state.filterLimit,
      page: page ? page : FIRST_PAGE,
      fromDate: this.state.fromdate,
      toDate: this.state.todate,
      referenceCode: ''
    };
    switch (this.filterCriteriaRef.current.value) {
      case FilterBillCode:
        filterParams.billCode = this.state.keySearch;
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
        break;
      case FilterReceiverCompanyName:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = this.state.keySearch;
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
        break;
      case FilterReceiverAddr:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = this.state.keySearch;
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
        break;
      case FilterSenderCompanyName:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = this.state.keySearch;
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.hawbCode = '';
        break;
      case FilterReferenceCode:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.logisticCode = '';
        filterParams.referenceCode = this.state.keySearch;
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
        break;
      case FilterLogisticCode:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = this.state.keySearch;
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
        break;
      case FilterService:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = this.state.keySearch;
        filterParams.hawbCode = '';
        break;
      case FilterHawb:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = this.state.keySearch;
        break;
      default:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
    }

    filterParams.searchReportStatus = this.state.searchReportStatus
    filterParams.searchStatusType1 = this.state.searchStatusType1
    filterParams.searchStatus = this.state.searchStatus
    filterParams.searchBranch = this.state.searchBranch

    if (this.filterCriteriaRef.current.value === 'customerId') {
      filterParams.customerId = this.state.keySearch;
    } else {
      filterParams.customerId = '';
    }

    this.setState({currentPage: filterParams.page})
    await this.props.dispatch(fetchPackages(filterParams));
    await this.fetchPackagesLocation()
  }

  formatDate = (str) => {
    return moment(str).format('DD-MM-YYYY hh:mm');
  }

  getItemWeight = (element, hawbIndex) => {
    try {
      let totalWeight = 0
      if (element.packageType === "doc") return element.docWeight || 0
      let packs = JSON.parse(element.packs) || []
      packs.forEach((packInfo, i) => {
        if (packInfo.weight && i === hawbIndex) {
          totalWeight = totalWeight + Rounding(packInfo.weight * packInfo.quantity)
        }
      })
      return totalWeight
    } catch (e) {
      return ''
    }
  }

  async exportTrackingData(type = '') {
    let filterParams = {
      companyUserID:
        (this.state.currUser.role === USER_ROLE.CUSTOMER || this.state.currUser.role === USER_ROLE.SALER)
          ? this.state.currUser.id
          : '',
      fromDate: this.state.fromdate,
      toDate: this.state.todate,
    };
    switch (this.filterCriteriaRef.current.value) {
      case FilterBillCode:
        filterParams.code = this.state.keySearch;
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        break;
      case FilterReceiverCompanyName:
        filterParams.code = '';
        filterParams.receiverCompanyName = this.state.keySearch;
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        break;
      case FilterReceiverAddr:
        filterParams.code = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = this.state.keySearch;
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        break;
      case FilterSenderCompanyName:
        filterParams.code = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = this.state.keySearch;
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        break;
      case FilterReferenceCode:
        filterParams.code = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = this.state.keySearch;
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        break;
      case FilterLogisticCode:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = this.state.keySearch;
        filterParams.logisticService = '';
        break;
      case FilterService:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = this.state.keySearch;
        break;
      case FilterHawb:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = this.state.keySearch;
        break;
      default:
        filterParams.code = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
    }
    await this.setState({disableDownloadButton: true});
    let url = '/download-tracking'
    axios.get(url, {
      params: filterParams,
      responseType: 'blob',
    }).then(res => {
      fileDownload(res.data, `TRACKING_${new Date().toJSON().slice(0, 10)}.xlsx`);
      this.setState({disableDownloadButton: false});
    }).catch(() => {
      this.setState({disableDownloadButton: false})
    });
    return
  }

  getHawbCode = (pkg) => {
    try {
      let hawbCode = ``
      if (pkg.hawbCode) {
        let hawbArr = pkg.hawbCode.trim().split(',')
        hawbArr.forEach((hawb, index) => {
          // return html code
          hawbCode += `<span class="hawb-code text-nowrap">${hawb} - <strong>${this.getItemWeight(pkg, index)}kg</strong></span>`
        })
      }
      return hawbCode
    } catch (e) {
      return ''
    }
  }

  formatRow = (pkg, packageInfo) => {
    // `d` is the original data object for the row
    if (!packageInfo) {
      packageInfo = {
        group: 'Hàng thường',
        totalPrice: '',
        bhPrice: '',
        customerId: '',
        note: ''

      }
    }
    let logicCode = pkg.logisticCode ? pkg.logisticCode : '';
    if (this.state.currUser.role !== USER_ROLE.OPS) {
      return (
        '<dl>' +
        '<strong>Sender: </strong>' + pkg.senderName + '<br/><br/>' +
        '<strong>Nhóm Hàng: </strong> ' + packageInfo?.group + '<br/><br/>' +
        '<strong>Số tiền thu khách: </strong> ' + pkg?.totalPrice + '<br/><br/>' +
        '<strong>Số tiền bảo hiểm: </strong> ' + packageInfo?.bhPrice + '<br/><br/>' +
        '<strong>Mã khách hàng: </strong> ' + packageInfo?.customerId + '<br/><br/>' +
        '<strong>Tracking: </strong> ' + logicCode + '<br/><br/>' +
        '<strong>Note: </strong> ' + packageInfo.note + '<br/><br/>' +
        '</dl>'
      );
    } else {
      return (
        '<dl>' +
        '<strong>Sender: </strong>' + pkg.senderName + '<br/><br/>' +
        '<strong>Mã khách hàng: </strong> ' + packageInfo?.customerId + '<br/><br/>' +
        '<strong>Tracking: </strong> ' + logicCode + '<br/><br/>' +
        '</dl>'
      );
    }

  }

  getPackageInfo = async (id) => {
    try {
      const data = await axios.get(`/get-packageinfo/${id}`)
      return data
    } catch (e) {
      return {}
    }
  }

  drtControl = async (e, pkg) => {
    e.preventDefault();
    const {data: packageInfo} = await this.getPackageInfo(pkg.kgCode);

    // show pkg info append after this tr tag
    let tr = e.target.closest('tr');
    let nextTr = tr.nextElementSibling;
    if (nextTr && nextTr.classList.contains('drt-info')) {
      nextTr.remove();
      e.target.classList.remove('fa-minus');
      e.target.classList.add('fa-plus');
    } else {
      let drtInfo = document.createElement('tr');
      drtInfo.classList.add('drt-info');
      drtInfo.innerHTML = `<td colspan="10">${this.formatRow(pkg, packageInfo)}</td>`;
      tr.after(drtInfo);

      // icon plus to minus
      e.target.classList.remove('fa-plus');
      e.target.classList.add('fa-minus');
    }

  }

  getPackageStatus = (pkg) => {
    const latestStatus = this.getLatestStatus(pkg)
    let action = ''
    if (latestStatus !== {} && latestStatus?.action) {
      action = latestStatus?.action || 'Create Bill'

      if (action === 'EXPORT') {
        if (pkg.exportType === 'DOMESTIC') {
          return <span className="badge badge-primary"><i className="fas fa-plane-departure mr-1"></i>Domestic</span>;
        }
        return <span className="badge badge-primary"><i className="fas fa-plane-departure mr-1"></i> Exported</span>;
      } else if (action === 'IMPORT') {
        return <span className="badge badge-warning"><i className="far fa-check-circle mr-1"></i> Imported</span>;
      } else if (action === 'RETURN') {
        return <span className="badge badge-danger"><i className="far fa-times-circle mr-1"></i> Return</span>;
      } else {
        return <span className="badge badge-secondary"><i className="far fa-clock mr-1"></i> Create Bill</span>;
      }
    } else {
      if (pkg.exportType) {
        return <span className="badge badge-primary"><i
          className="fas fa-plane-departure mr-1"></i> {pkg.exportType === 'EXPORT' ? 'Exported' : pkg.exportType}</span>;
      }
      if (pkg.exportAt) {
        return <span className="badge badge-primary"><i className="fas fa-plane-departure mr-1"></i> Exported</span>;
      } else if (pkg.importAt) {
        return <span className="bage badge-warning"><i className="far fa-check-circle mr-1"></i> Imported</span>;
      } else {
        return <span className="badge badge-secondary"><i className="far fa-clock mr-1"></i>Create Bill</span>;
      }
    }
  }

  getHawbLocation = (hawbCode) => {
    if (hawbCode && this.state.locations && this.state.locations.length > 0) {
      // split hawbCode and get from locations
      let hawbArr = hawbCode.trim().split(',')
      let locationArr = []
      hawbArr.forEach((hawb) => {
        // trim hawb
        let location = this.state.locations.find((loc) => loc.hawbCode === hawb?.trim())
        if (location) {
          locationArr.push(location.branch)
        }
      })
      // return format item1 <br /> item2
      return locationArr.join('<br />')
    }
  }

  getLatestStatus = (pkg) => {
    const hawbCode = pkg.hawbCode
    if (hawbCode && this.state.locations && this.state.locations.length > 0) {
      // split hawbCode and get from locations
      let hawbArr = hawbCode.trim().split(',')
      let locationArr = []
      hawbArr.forEach((hawb) => {
        let location = this.state.locations.find((loc) => loc.hawbCode === hawb)
        if (location) {
          locationArr.push(location)
        }
      })
      // desc sort by date
      locationArr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      return locationArr[0]
    }
    return {}
  }

  getPackageReportStatus = (pkg) => {
    if (pkg.reportStatus === 'Đã thanh toán') {
      return <span className="badge badge-success"><i className="far fa-clock mr-1"></i>Đã thanh toán</span>;
    } else if (pkg.reportStatus === 'Công nợ') {
      return <span className="badge badge-secondary"><i className="far fa-check-circle mr-1"></i> Công nợ</span>;
    } else {
      return <span className="badge badge-danger"><i className="far fa-times-circle mr-1"></i> Chưa thanh toán</span>;
    }
  }
  getPackagestatusType1 = (pkg) => {
    if (pkg.statusType1 === 'Đã làm chứng từ') {
      return <span className="badge badge-success"><i className="far fa-clock mr-1"></i>Đã làm chứng từ</span>;
    } else {
      return <span className="badge badge-secondary"><i
        className="far fa-check-circle mr-1"></i> Chưa làm chứng từ</span>;
    }
  }

  async exportData(type = '') {
    console.log('export data', this.state.currUser)
    let filterParams = {
      companyUserID:
        (this.state.currUser.role === USER_ROLE.OPS || this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT)
          ? this.state.currUser.id
          : '',
      owner: (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) ? this.state.currUser.displayName : "",
      fromDate: this.state.fromdate,
      toDate: this.state.todate,
    };

    switch (this.filterCriteriaRef.current.value) {
      case FilterBillCode:
        filterParams.code = this.state.keySearch;
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
        break;
      case FilterReceiverCompanyName:
        filterParams.code = '';
        filterParams.receiverCompanyName = this.state.keySearch;
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
        break;
      case FilterReceiverAddr:
        filterParams.code = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = this.state.keySearch;
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
        break;
      case FilterSenderCompanyName:
        filterParams.code = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = this.state.keySearch;
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
        break;
      case FilterReferenceCode:
        filterParams.code = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = this.state.keySearch;
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
        break;
      case FilterLogisticCode:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = this.state.keySearch;
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
        break;
      case FilterService:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = this.state.keySearch;
        filterParams.hawbCode = '';
        break;
      case FilterHawb:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = this.state.keySearch;
        break;
      default:
        filterParams.code = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        filterParams.logisticService = '';
        filterParams.hawbCode = '';
    }
    filterParams.searchReportStatus = this.state.searchReportStatus
    filterParams.searchStatusType1 = this.state.searchStatusType1
    filterParams.searchStatus = this.state.searchStatus
    filterParams.searchBranch = this.state.searchBranch

    if (this.filterCriteriaRef.current.value === 'customerId') {
      filterParams.customerId = this.state.keySearch;
    } else {
      filterParams.customerId = '';
    }

    await this.setState({disableDownloadButton: true});
    let url = '/download'
    if (type === 'manifest') {
      url = '/download-manifest'
    }
    axios.get(url, {
      params: filterParams,
      responseType: 'blob',
    }).then(res => {
      fileDownload(res.data, `DOWNLOAD_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`);
      this.setState({disableDownloadButton: false});
    }).catch(() => {
      this.setState({disableDownloadButton: false})
    });
    return
  }

  async uploadTrackingData(type = '') {
    this.modalUploadToggle()
  }

  async submitPickup() {
    let {pickup} = this.state
    if (!pickup.packageId || !pickup.datetime || !pickup.address) {
      toast.error('Vui lòng nhập đầy đủ thông tin');
      return
    }
    const currentPackage = await this.getLogisticCode(pickup.packageId)
    if (!currentPackage.data) {
      toast.error('Đơn hàng không tồn tại trong hệ thống');
      return;
    }
    pickup.companyUserID = this.state.currUser.id
    if (this.state.currUser.role === USER_ROLE.ADMIN || this.state.currUser.role === USER_ROLE.OPS || this.state.currUser.id === currentPackage.data.companyUserID) {
      this.props.dispatch(createPickup(pickup))
      this.setState({pickup: {}, pickupStatus: 2})
      toast.success('Tạo pickup thành công!');
      await this.fetchPickup()
    } else {
      toast.error('Account không có quyền tạo pickup cho đơn hàng này');
      return;
    }

  }

  async getLogisticCode(code) {
    return axios.get(`/package-by-code/${code}`)
  }

  async fetchPickup() {
    let token = localStorage.getItem('id_token');
    let currUser = jwt.decode(token);
    const a = await axios.post('/list-pickup', {
      isAdmin: currUser.role === USER_ROLE.ADMIN || currUser.role === USER_ROLE.OPS,
      userId: currUser.id
    })
    if (a.data && a.data.list) {
      this.setState({listPickup: a.data.list})
    }
  }

  async deletePickup(id) {
    this.props.dispatch(deletePickup(id));
    setTimeout(async () => {
      await this.fetchPickup()
    }, 500)
  }

  async updatePickup(id) {
    await axios.post('/update-pickup', {id, status: 'Delivered'})
    await this.fetchPickup()
  }

  checkCanEdit = (pkg) => {
    if (this.state.currUser.role !== USER_ROLE.SHIPPER && this.state.currUser.role !== USER_ROLE.SALER && this.state.currUser.role !== USER_ROLE.OPS && this.state.currUser.role !== USER_ROLE.CUSTOMER && this.state.currUser.role !== USER_ROLE.MANAGER) {
      if (this.state.currUser.role === USER_ROLE.MANAGER || this.state.currUser.role === USER_ROLE.CONSULTANT) {
        let createdAt = moment(pkg.createdAt)
        let now = moment()
        let diff = now.diff(createdAt, 'minutes')
        if (diff > 30) {
          return false
        }
      }
      return true;
    }
    return false
  }

  checkCanAction = (pkg) => {
    // khac  ROLE SALE, TƯ VẤN VIÊN, MANAGER
    if (this.state.currUser.role !== USER_ROLE.SALER
      && this.state.currUser.role !== USER_ROLE.CONSULTANT
      && this.state.currUser.role !== USER_ROLE.MANAGER) {
      return true;
    }
    return false
  }

  isGiaoNhan = () => {
    return this.state.currUser.role === USER_ROLE.SHIPPER
  }

  isRingoPost = (pkg) => {
    if (pkg && pkg?.kgCode && pkg?.kgCode.startsWith('96')) {
      return false;
    }
    if (pkg && pkg.owner && (pkg.owner.toLowerCase().includes('ringo') || pkg.owner.toLowerCase().includes('rg -'))) {
      return false
    }
    return true
  }

  render() {
    const printBill = () => {
      return `@media print {
        @page { 
          size: A4 portrait;
          margin: 5mm;
        }  }
        
        table, tbody,td,tr {  
         border: 1px solid black ;
         padding-left: 10px; 
         font-familly: "Times New Roman"; 
        }
      
      `;
    };

    const printInvPage = () => {
      return `@media print {
        @page {
          size: A4 portrait;
          margin: 5mm;
          margin-top: 20mm;
        }  }
         
        table, tbody,th,td,tr {  
          border: 1px solid black;
          font-familly: "Times New Roman"; 
          font-size="40px";
         }
        `;
    };

    const printPackages = () => {
      if (this.isRingoPost(this.state.printInvData.packageInfo)) {
        return `@media print {
        @page {
          size: A4 portrait;
          margin: 5mm;
          margin-top: 5mm;
        }  }
         
        table, tbody,th,td,tr {  
          border: 1px solid black;
          font-familly: "Times New Roman"; 
          font-size="40px";
         }
        `;
      } else {
        return `@media print {
        @page {
          size: A4 landscape;
          margin: 5mm;
          margin-top: 5mm;
        }  }
        `;
      }


    };

    const printSM = () => {
      // return `@media print {
      //   @page {
      //     size: A6 portrait;
      //     margin: 5mm;
      //     margin-top: 5mm;
      //   }  }
      //   `;
    };

    // check role == SHIPPER
    let packages = this.props.packages

    if (this.state.currUser.role === USER_ROLE.SHIPPER) {
      try {
        let userLocation = this.state.currUser?.location || 'HCM'
        if (userLocation === "null") {
          userLocation = 'HCM'
        }
        if (packages && packages.length) {
          packages = packages.filter((pkg) => {
            let hawbCode = pkg.hawbCode
            let result = false
            if (hawbCode && this.state.locations && this.state.locations.length > 0) {
              // split hawbCode and get from locations
              let location = this.state.locations.find((loc) => hawbCode.includes(loc.hawbCode.trim()) || hawbCode === loc.hawbCode.trim())
              if (location && location.branch && location.branch === userLocation) {
                result = true
              }
              console.log(location)
              if (location && location.action === 'EXPORT') {
                // check location.createdAt > current date 1 day -> return false
                let createdAt = moment(location.createdAt)
                let now = moment()
                let diff = now.diff(createdAt, 'hours')
                console.log(diff)
                if (diff > 24) {
                  result = false
                }
              }
            }
            return result
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
      let totalPacks = 0;
      packages &&
      packages.forEach((element) => {
        let packs = JSON.parse(element.packs);
        if (packs.length && packs[0].quantity) {
          totalPacks = packs.reduce(function (a, b) {
            return a + parseInt(b['quantity'])
          }, 0)
        }
      });
      let totalWeight = parseFloat(0);
      packages &&
      packages.forEach((element) => {
        let packs = JSON.parse(element.packs);
        packs.forEach((packInfo) => {
          if (packInfo.weight) {
            totalWeight =
              totalWeight + Rounding(packInfo.weight * packInfo.quantity);
          }
        });
      });

      // paging
      let totalPage = parseInt(this.props.totalPackages / this.state.filterLimit);
      if (this.props.totalPackages % this.state.filterLimit > 0) {
        totalPage++;
      }

      const {currentPage, disableDownloadButton} = this.state
      let pageLimit = 10
      let start = 0
      let end = pageLimit
      if (totalPage <= pageLimit) {
        pageLimit = totalPage
      }
      if (currentPage - 5 >= 0) {
        start = currentPage - 4
      }
      if (start + pageLimit >= totalPage) {
        start = totalPage - pageLimit
      }
      if (currentPage + 5 >= pageLimit) {
        end = currentPage + 6
        pageLimit = end
        if (totalPage <= pageLimit) {
          pageLimit = totalPage
        }
      }

      const statusTypeOption = {
        PROGRESS: "Đang vận chuyển",
        PROCESSING: "Sắp giao"
      }
      const documentStatusOption = {
        PROGRESS: "Chưa làm chứng từ",
        PROCESSING: "Đã làm chứng từ"
      }
      return (
        <div className='packet-list'>
          {/* Update/Delete package */}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
          />


          <Breadcrumb>
            <BreadcrumbItem>TRANG</BreadcrumbItem>
            <BreadcrumbItem active>Kiện hàng</BreadcrumbItem>
          </Breadcrumb>

          <div className="tp-table">
            <div className="tp-table__query">
              <div className="search-date d-flex align-item-center">
                <div className="search-date-item">
                  <label htmlFor='fromDate'>
                    Date
                  </label>
                  <DatePicker
                    showIcon
                    icon="fa fa-calendar"
                    id='fromDate'
                    selected={this.state.fromdate}
                    onSelect={this.handleDatefromSelect}
                  />
                </div>
                <div className="search-date-item">
                  <label htmlFor='toDate'>
                    To Date
                  </label>
                  <DatePicker
                    showIcon
                    icon="fa fa-calendar"
                    id='toDate'
                    selected={this.state.todate}
                    onSelect={this.handleDatetoSelect}
                  />
                </div>
              </div>
              <div className="search-query d-flex justify-content-end align-items-center flex-wrap">
                <div className="search-query-item">
                  <select
                    id="statusType1"
                    name="statusType1"
                    onChange={(e) => this.onChangeStatusType1(e)}
                  >
                    <option value=''>--- Chứng từ ---</option>
                    <option value='Đã làm chứng từ'>Đã làm chứng từ</option>
                    <option value='Chưa làm chứng từ'>Chưa làm chứng từ</option>
                  </select>
                </div>
                <div className="search-query-item">
                  <select
                    id="tracking"
                    name="tracking"
                    onChange={(e) => this.onChangeStatus(e)}
                  >
                    <option value=''>Status trạng thái</option>
                    <option value='Created'>Created</option>
                    <option value='Imported'>Imported</option>
                    <option value='Exported'>Exported</option>
                    <option value='Domestic'>Domestic</option>
                  </select>
                </div>
                <div className="search-query-item">
                  <select
                    id="tracking"
                    name="tracking"
                    onChange={(e) => this.onChangeReportStatus(e)}
                  >
                    <option value=''>--- Thanh toán ---</option>
                    <option value='Chưa thanh toán'>Chưa thanh toán</option>
                    <option value='Đã thanh toán'>Đã thanh toán</option>
                    <option value='Công nợ'>Công nợ</option>
                  </select>
                </div>
                {/*<div className="search-query-item">*/}
                {/*  <select*/}
                {/*    id="Branch"*/}
                {/*    name="Branch"*/}
                {/*    onChange={(e) => this.onChangeBranch(e)}*/}
                {/*  >*/}
                {/*    <option value=''>Branch</option>*/}
                {/*    <option value='HN'>Hà Nội</option>*/}
                {/*    <option value='DN'>Đà Nẵng</option>*/}
                {/*    <option value='HCM'>HCM</option>*/}
                {/*  </select>*/}
                {/*</div>*/}
                <div className="search-query-item">
                  <select
                    id="type"
                    ref={this.filterCriteriaRef}
                  >
                    <option value='billCode'>Search by TP Bill</option>
                    <option value='receiverCompanyName' defaultValue>
                      Search by Receiver Name
                    </option>
                    <option value='senderCompanyName'>Search by Owner</option>
                    <option value='receiverAddr'>Search by Address</option>
                    <option value='referenceCode'>Search by Reference Code</option>
                    <option value='logisticCode'>Search by Tracking</option>
                    <option value='logisticService'>Search by Service</option>
                    <option value='hawbCode'>Search by HAWB</option>
                    <option value='customerId'>Search by mã KHTP</option>
                  </select>
                </div>

                <div className='search-query-item'>
                  <input
                    style={{width: '100%', padding: '5px 15px'}}
                    type='text'
                    className='form-control input-search'
                    id='tim'
                    value={this.state.keySearch}
                    placeholder='Search'
                    onChange={(e) => this.handleChange(e.target.value)}
                    ref={this.filterValueRef}
                  />
                </div>
                <button
                  id="tim"
                  className="search-query-item btn btn-danger"
                  onClick={(e) => this.onSearch(e)}
                >
                  <i className="fas fa-search mr-1"></i>
                  Search
                </button>
              </div>

            </div>

            <hr/>

            <div>

              <div className="top-info">
                <div className="top-info-button d-flex align-items-center">
                  <Link to="/app/bill" className="btn btn-danger">
                    <i className="fas fa-file-alt mr-1"></i>
                    Tạo Hoá Đơn</Link>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => this.exportData()}
                    disabled={disableDownloadButton}
                  >
                    <i className="fas fa-download mr-1"></i>
                    Download
                  </button>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => this.exportTrackingData()}
                    disabled={disableDownloadButton}
                  >
                    <i className="fas fa-download mr-1"></i>
                    Download Tracking
                  </button>
                  {
                    (this.state.currUser.role === USER_ROLE.ADMIN || this.state.currUser.role === USER_ROLE.DOCUMENT) && (
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => this.uploadTrackingData()}
                        disabled={disableDownloadButton}
                      >
                        <i className="fas fa-upload mr-1"></i>
                        Upload Tracking
                      </button>
                    )
                  }
                </div>
                <div className="top-info-data d-flex justify-content-between">
                  <div className="top-info-left d-flex align-items-center">
                    Show
                    <select
                      id="type"
                      onChange={(e) => this.onChangeLimit(e)}
                    >
                      <option defaultValue='20'>20</option>
                      <option value='40'>40</option>
                      <option value='60'>60</option>
                      <option value='80'>80</option>
                      <option value='100'>100</option>
                    </select>
                    entries
                  </div>
                  <div className="top-info-right d-flex justify-content-end">
                    <div className="top-info-right-item">
                      <span>Total Result: <strong>{this.props.totalPackages}</strong></span>
                    </div>
                    <div className="top-info-right-item">
                      <span>Total Pcs: <strong>{totalPacks}</strong></span>
                    </div>
                    <div className="top-info-right-item">
                      <span>Total Weight: <strong>{Rounding(totalWeight)}</strong>(kg)</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-wrapper">
                <table className="table table-striped table-hover">
                  <thead>
                  <tr>
                    <th></th>
                    <th>TP Code</th>
                    <th>Hawb Code</th>
                    <th>Date</th>
                    {(this.state.currUser.role !== USER_ROLE.OPS && this.state.currUser.role !== USER_ROLE.CONSULTANT && this.state.currUser.role !== USER_ROLE.SALER && this.state.currUser.role !== USER_ROLE.MANAGER) && (
                      <th>Ref Code</th>
                    )}
                    <th>Service</th>
                    <th>Receiver</th>
                    <th>Address</th>
                    <th>Owner</th>
                    <th>Status</th>
                    <th>Location</th>
                    {
                      this.checkCanAction() && (
                        <th>Action</th>
                      )
                    }
                  </tr>
                  </thead>

                  <tbody>
                  {packages?.length ?
                    packages.map((pkg, i) => (
                      <tr key={i}>
                        <td>
                          {
                            !this.isGiaoNhan() && (
                              <a href="#" onClick={(e) => this.drtControl(e, pkg)}>
                                <i className="fas fa-plus-circle text-primary"></i>
                              </a>
                            )
                          }

                        </td>
                        <td className=''>
                          <Link to={`/app/packet-detail/${pkg.id}`} target="_blank"
                                rel="noopener noreferrer"> {pkg.kgCode || ''}</Link>
                        </td>
                        <td>
                          <div className="d-flex flex-column"
                               dangerouslySetInnerHTML={{__html: this.getHawbCode(pkg)}}/>
                        </td>
                        <td className='text-nowrap'>
                          {this.formatDate(pkg.createdAt)}
                        </td>
                        {(this.state.currUser.role !== USER_ROLE.OPS && this.state.currUser.role !== USER_ROLE.CONSULTANT && this.state.currUser.role !== USER_ROLE.SALER && this.state.currUser.role !== USER_ROLE.MANAGER) && (
                          <td className=''>{pkg.referenceCode || ''}</td>
                        )}
                        <td className=''>
                          {pkg.logisticService}
                        </td>
                        <td>
                          {pkg.receiverName}
                        </td>
                        <td className=''>
                          <a href={`https://tracking.gopost.vn?tpCode=${pkg.kgCode}`} target='_blank'
                             rel="noopener noreferrer">
                            [&nbsp;<i className="fas fa-map-marker-alt text-blue mr-1"></i> {pkg.receiverCountry}]
                          </a>
                          &nbsp;- {pkg.receiverCity}
                        </td>
                        <td>
                          {pkg.owner}
                        </td>
                        <td>
                          <div className="table-status text-center">
                            {this.getPackageStatus(pkg)}
                            <div style={{marginTop: '5px'}}>
                              {this.getPackageReportStatus(pkg)}
                            </div>
                            <div style={{marginTop: '5px'}}>
                              {this.getPackagestatusType1(pkg)}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div dangerouslySetInnerHTML={{__html: this.getHawbLocation(pkg.hawbCode)}}></div>
                        </td>
                        {
                          this.checkCanAction() && (
                            <td>
                              <div className="print-btn">
                                <div style={{whiteSpace: 'nowrap', display: 'flex', gap: '5px'}}>
                                  <button
                                    type="button"
                                    id={pkg.id}
                                    className="btn btn-transparent btn-sm"
                                    value={pkg}
                                    onClick={(e) => this.onClickPrint(e, pkg)}
                                  >
                                    <i className="fa fa-print"/>P
                                  </button>


                                  <button
                                    type="button"
                                    className="btn btn-transparent btn-sm"
                                    id={pkg.id}
                                    value={pkg}
                                    onClick={(e) => this.onClickPrintInv(e, pkg)}
                                  >
                                    <i className="fa fa-print"/>Inv
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-transparent btn-sm"
                                    id={pkg.id}
                                    value={pkg}
                                    onClick={(e) => this.onClickPrintSM(e, pkg)}
                                  >
                                    <i className="fa fa-print"/>SM
                                  </button>
                                </div>

                                <div style={{whiteSpace: 'nowrap', display: 'flex', gap: '5px'}}>
                                  {
                                    (this.state.currUser.role !== USER_ROLE.OPS && this.state.currUser.role !== USER_ROLE.SHIPPER) && (
                                      <button
                                        type="button"
                                        className="btn btn-transparent btn-sm"
                                        id={pkg.id}
                                        value={pkg}
                                        onClick={(e) => this.onClickPrintPackages(e, pkg)}
                                      >
                                        <i className="fa fa-print"/>B
                                      </button>
                                    )
                                  }
                                  {this.checkCanEdit(pkg) ? (
                                    <button
                                      type="button"
                                      style={{marginBottom: '5px'}}
                                      className="btn btn-transparent btn-sm btn-edit"
                                      onClick={(e) => this.props.history.push(`/app/bill/${pkg.kgCode}`)}
                                    >
                                      <i className="far fa-edit"/>E
                                    </button>
                                  ) : ''}


                                  {(this.state.currUser.role === USER_ROLE.ADMIN
                                    || this.state.currUser.role === USER_ROLE.DOCUMENT || this.state.currUser.email == 'customerservicetinphat@gmail.com') && (
                                    <button
                                      className="btn btn-transparent btn-sm btn-edit"
                                      onClick={(e) => this.toggleUpdatePackage(e, pkg)}
                                    >
                                      <i className="far fa-edit"/> U/D
                                    </button>
                                  )}
                                </div>
                              </div>
                            </td>
                          )
                        }


                      </tr>
                    )) : (
                      <tr>
                        <td colSpan='100' className='text-center'>Danh sách trống. Vui lòng kiểm tra lại bộ lọc.</td>
                      </tr>
                    )}

                  {this.props.isFetching && (
                    <tr>
                      <td colSpan='100'>Loading...</td>
                    </tr>
                  )}
                  </tbody>
                </table>
                {totalPage > 1 && (
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem>
                      <PaginationLink first href="#" onClick={(e) => this.onSearch(e, 1)}/>
                    </PaginationItem>
                    {[...Array(pageLimit)].map((_, i) => {
                      if (i >= start && i < end) {
                        return (
                          <PaginationItem active={i === currentPage - 1} key={i}>
                            {' '}
                            <PaginationLink
                              onClick={(e) => this.onSearch(e, i + 1)}
                            >
                              {' '}{i + 1}{' '}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }
                    })}
                    <PaginationItem>
                      <PaginationLink last href="#" onClick={(e) => this.onSearch(e, totalPage)}/>
                    </PaginationItem>
                  </Pagination>
                )}
              </div>

            </div>
          </div>

          <Modal
            isOpen={this.state.modalToggleUpdatePackage}
            toggle={this.toggleUpdatePackage}
            size="lg"
            className="modal-edit-packet"
            centered
          >
            <ModalBody>
              <img src={EditPacketTitle} alt="title"/>
              <Row>
                <Col>
                  {/*  <FormGroup>*/}
                  {/*    <Label for='receiverCompanyName'>Receiver Company Name</Label>*/}
                  {/*    <Input*/}
                  {/*      value={this.state.updatingPackage.receiverCompanyName}*/}
                  {/*      name='receiverCompanyName'*/}
                  {/*      id='receiverCompanyName'*/}
                  {/*      placeholder='receiver name'*/}
                  {/*      onChange={(e) => this.onChangeUpdatePackage(e)}*/}
                  {/*    />*/}
                  {/*  </FormGroup>*/}
                  {/*  <FormGroup>*/}
                  {/*    <Label for='receiverName'>Receiver Name</Label>*/}
                  {/*    <Input*/}
                  {/*      value={this.state.updatingPackage.receiverName}*/}
                  {/*      name='receiverName'*/}
                  {/*      id='receiverName'*/}
                  {/*      placeholder='receiver name'*/}
                  {/*      onChange={(e) => this.onChangeUpdatePackage(e)}*/}
                  {/*    />*/}
                  {/*  </FormGroup>*/}
                  {/*  <FormGroup>*/}
                  {/*    <Label for='receiverPhone'>Receiver Phone</Label>*/}
                  {/*    <Input*/}
                  {/*      value={this.state.updatingPackage.receiverPhone}*/}
                  {/*      name='receiverPhone'*/}
                  {/*      id='receiverPhone'*/}
                  {/*      placeholder='receiver phone'*/}
                  {/*      onChange={(e) => this.onChangeUpdatePackage(e)}*/}
                  {/*    />*/}
                  {/*  </FormGroup>*/}
                  {/*  <FormGroup>*/}
                  {/*    <Label for='receiverCountry'>Receiver Country</Label>*/}
                  {/*    <Input*/}
                  {/*      value={this.state.updatingPackage.receiverCountry}*/}
                  {/*      name='receiverCountry'*/}
                  {/*      id='receiverCountry'*/}
                  {/*      placeholder='receiver country'*/}
                  {/*      onChange={(e) => this.onChangeUpdatePackage(e)}*/}
                  {/*    />*/}
                  {/*  </FormGroup>*/}
                  {/*  <FormGroup>*/}
                  {/*    <Label for='receiverCity'>Receiver City</Label>*/}
                  {/*    <Input*/}
                  {/*      value={this.state.updatingPackage.receiverCity}*/}
                  {/*      name='receiverCity'*/}
                  {/*      id='receiverCity'*/}
                  {/*      placeholder='receiver city'*/}
                  {/*      onChange={(e) => this.onChangeUpdatePackage(e)}*/}
                  {/*    />*/}
                  {/*  </FormGroup></Col>*/}
                  {/*<Col><FormGroup>*/}
                  {/*  <Label for='receiverProvince'>Receiver Province</Label>*/}
                  {/*  <Input*/}
                  {/*    value={this.state.updatingPackage.receiverProvince}*/}
                  {/*    name='receiverProvince'*/}
                  {/*    id='receiverProvince'*/}
                  {/*    placeholder='receiver province'*/}
                  {/*    onChange={(e) => this.onChangeUpdatePackage(e)}*/}
                  {/*  />*/}
                  {/*</FormGroup>*/}
                  {/*  <FormGroup>*/}
                  {/*    <Label for='referenceCode'>TP Code</Label>*/}
                  {/*    <Input*/}
                  {/*      value={this.state.updatingPackage.referenceCode}*/}
                  {/*      name='referenceCode'*/}
                  {/*      id='referenceCode'*/}
                  {/*      placeholder='TP Code'*/}
                  {/*      onChange={(e) => this.onChangeUpdatePackage(e)}*/}
                  {/*    />*/}
                  {/*  </FormGroup>*/}
                  {/*  <FormGroup>*/}
                  {/*    <Label for='receiverAddr1'> Address 1</Label>*/}
                  {/*    <Input*/}
                  {/*      value={this.state.updatingPackage.receiverAddr1}*/}
                  {/*      name='receiverAddr1'*/}
                  {/*      id='receiverAddr1'*/}
                  {/*      placeholder='Receiver Address 1'*/}
                  {/*      onChange={(e) => this.onChangeUpdatePackage(e)}*/}
                  {/*    />*/}
                  {/*  </FormGroup>*/}
                  {/*  <FormGroup>*/}
                  {/*    <Label for='receiverAddr2'> Address 2</Label>*/}
                  {/*    <Input*/}
                  {/*      value={this.state.updatingPackage.receiverAddr2}*/}
                  {/*      name='receiverAddr2'*/}
                  {/*      id='receiverAddr2'*/}
                  {/*      placeholder='Receiver Address 2'*/}
                  {/*      onChange={(e) => this.onChangeUpdatePackage(e)}*/}
                  {/*    />*/}
                  {/*  </FormGroup>*/}
                  <FormGroup>
                    <Label for='logisticCode'> Tracking Code</Label>
                    <Input
                      value={this.state.updatingPackage.logisticCode}
                      name='logisticCode'
                      id='logisticCode'
                      placeholder='Tracking Code'
                      onChange={(e) => this.onChangeUpdatePackage(e)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for='status'>Trạng thái đơn hàng</Label>
                    <Input id="status" name="status" type="select" onChange={this.onChangeUpdatePackage}
                           value={this.state.updatingPackage.status}>
                      {Object.values(statusTypeOption).map((object, i) => <option className="text-capitalize"
                                                                                  value={object}
                                                                                  key={i}>{object}</option>)}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for='status'>Chứng từ</Label>
                    <Input id="statusType1" name="statusType1" type="select" onChange={this.onChangeUpdatePackage}
                           value={this.state.updatingPackage.statusType1}>
                      {Object.values(documentStatusOption).map((object, i) => <option className="text-capitalize"
                                                                                      value={object}
                                                                                      key={i}>{object}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type='submit'
                color='success'
                className="btn-add btn-update"
                onClick={(e) => this.onUpdatePackage(e)}
              >
                Update
              </Button>
              {
                (this.state.currUser.role === USER_ROLE.ADMIN || this.state.currUser.role === USER_ROLE.DOCUMENT) && (
                  <Button
                    type='submit'
                    color='danger'
                    className="btn-delete"
                    onClick={(e) => this.onDeletePackage(e)}
                  >
                    Delete
                  </Button>
                )
              }

              <Button color='secondary' className="btn-cancel" onClick={this.toggleUpdatePackage}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          {/* Print Bill */}
          <Modal
            isOpen={this.state.modalToggle}
            scrollable
            centered
            size='xl'
            fullscreen="true |xxl-down'"
            toggle={this.printToggle}
          >
            <ModalHeader toggle={this.printToggle}>Bill</ModalHeader>
            <ModalBody>
              <style> {printBill()} </style>
              {' '}
              <div>
                {' '}
                <BillToPrint
                  billData={this.state.printData}
                  ref={(el) => (this.componentRef = el)}
                />{' '}
              </div>
            </ModalBody>
            <ModalFooter>
              <ReactToPrint
                trigger={() => {
                  return <Button href='#/app/bill'> Print this out!</Button>;
                }}
                content={() => this.componentRef}
              />
            </ModalFooter>
          </Modal>

          {/* Print Invoice */}
          <Modal
            isOpen={this.state.modalInvToggle}
            scrollable
            centered
            size='xl'
            fullscreen="true |xxl-down'"
            toggle={this.printInvToggle}
          >
            <ModalHeader toggle={this.printInvToggle}>Invoice</ModalHeader>
            <ModalBody>
              <style> {printInvPage()} </style>
              {' '}
              <InvoiceToPrint
                invData={this.state.printInvData}
                ref={(el) => (this.componentRef = el)}
              />
            </ModalBody>
            <ModalFooter>
              <ReactToPrint
                trigger={() => {
                  return <Button href='#/app/packet'> Print this out!</Button>;
                }}
                content={() => this.componentRef}
              />
            </ModalFooter>
          </Modal>

          {/* Print Packages */}
          <Modal
            isOpen={this.state.modalPrintPackagesToggle}
            scrollable
            centered
            size='xl'
            fullscreen="true |xxl-down'"
            toggle={this.printPackagesToggle}
          >
            <ModalHeader toggle={this.printPackagesToggle}>
              Print Billup
            </ModalHeader>
            <ModalBody>
              <style> {printPackages()} </style>
              {' '}
              <div className="print-wrapper">
                {/*{' '}*/}
                {/*<PrintPackages*/}
                {/*  billData={this.state.printInvData}*/}
                {/*  ref={(el) => (this.componentRef = el)}*/}
                {/*/>{' '}*/}

                {
                  this.isRingoPost(this.state.printInvData.packageInfo) ? (
                    <PrintBillup
                      // invData={this.state.printInvData}
                      billData={this.state.printInvData}
                      ref={(el) => (this.componentRef = el)}
                    />
                  ) : (
                    <RingoPrintBillup
                      // invData={this.state.printInvData}
                      billData={this.state.printInvData}
                      ref={(el) => (this.componentRef = el)}
                    />
                  )
                }

              </div>
            </ModalBody>
            <ModalFooter>
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button href='#/app/printPackages'> Print this out!</Button>
                  );
                }}
                content={() => this.componentRef}
              />
            </ModalFooter>
          </Modal>

          {/* Print SM */}
          <Modal
            isOpen={this.state.modalSMToggle}
            scrollable
            centered
            size='xl'
            fullscreen="true |xxl-down'"
            toggle={this.printSMToggle}
          >
            <ModalHeader toggle={this.printSMToggle}>
              Shipping Mark
            </ModalHeader>
            <ModalBody>
              <style> {printSM()} </style>
              {' '}
              <div className="print-wrapper">
                {' '}
                <PrintSMOPS
                  billData={this.state.printSMData}
                  ref={(el) => (this.componentRef = el)}
                />
                {/*{*/}
                {/*  // check bill owner role*/}
                {/*  this.state.pkRole === USER_ROLE.OPS ? (*/}
                {/*    <PrintSMOPS*/}
                {/*      billData={this.state.printSMData}*/}
                {/*      ref={(el) => (this.componentRef = el)}*/}
                {/*    />*/}
                {/*  ) : (*/}
                {/*    <PrintSM*/}
                {/*      billData={this.state.printSMData}*/}
                {/*      ref={(el) => (this.componentRef = el)}*/}
                {/*    />*/}
                {/*  )*/}
                {/*}*/}
              </div>
            </ModalBody>
            <ModalFooter>
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button href='#/app/printPackages'> Print this out!</Button>
                  );
                }}
                content={() => this.componentRef}
              />
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.modalTogglePickup}
            toggle={this.toggleShowPickupModal}
            size="xl"
            fullscreen="true |xxl-down'"
            style={{transform: "translateY(100px)"}}
          >
            <div className={s.contentBoxPickupModal}>
              <ModalHeader
                toggle={this.toggleShowPickupModal}
                style={{paddingRight: '15px'}}
              >
              </ModalHeader>
              <div className={s.headerPickupModal}>
                <div
                  className={`${s.headerItem} ${this.state.pickupStatus === 1 &&
                  s.headerItemChecked}`}
                  onClick={() => this.togglePickupStatus(1)}
                >
                  Request a pickup
                </div>
                <div
                  className={`${s.headerItem} ${this.state.pickupStatus === 2 &&
                  s.headerItemChecked}`}
                  onClick={() => this.togglePickupStatus(2)}
                >
                  Pickup List
                </div>
              </div>
              <ModalBody style={{padding: "20px"}}>
                {this.state.pickupStatus === 1 ? (
                  <div className={s.bodyPickupModal}>
                    <form>
                      <Row>
                        <Col>
                        <textarea
                          rows="14"
                          style={{
                            minWidth: "100%",
                            border: "1px solid #d9d9d9",
                            borderRadius: "4px",
                            padding: "4px 11px",
                          }}
                          name="packageId"
                          onChange={this.onCreatePickupChange}
                          placeholder="Please input your order number here that need pickup service"
                        />
                        </Col>
                        <Col>
                          <Form>
                            <FormGroup row>
                              <Label for="pickupType" sm={4}>
                                Pickup Type
                              </Label>
                              <Col sm={8}>
                                <Input
                                  id="pickupType"
                                  name="type"
                                  type="select"
                                  onChange={this.onCreatePickupChange}
                                  required
                                >
                                  <option>Xe tải</option>
                                  <option>Bán tải</option>
                                  <option>Xe máy</option>
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label for="pickupDate" sm={4}>
                                Pickup Datetime
                              </Label>
                              <Col sm={8}>
                                <DatePicker
                                  className={`form-control pickup-date ${s.pickupDateTimeModal}`}
                                  id="pickupDate"
                                  placeholderText="Pickup Datetime"
                                  name="datetime"
                                  dateFormat="dd/MM/yy"
                                  selected={this.state.pickup.datetime}
                                  onChange={this.handleDatePickupSelect}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label for="pickupAddress" sm={4}>
                                Pickup Address
                              </Label>
                              <Col sm={8}>
                                <Input id="pickupAddress" name="address"
                                       onChange={this.onCreatePickupChange} required/>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label for="pickupAddress" sm={4}>
                                Gross Weight
                              </Label>
                              <Col sm={8}>
                                <Input id="grossWeight" name="grossWeight" onChange={this.onCreatePickupChange}
                                       required/>
                              </Col>
                            </FormGroup>
                            <FormGroup row style={{paddingBottom: "50px"}}>
                              <Label for="pickupRemark" sm={4}>
                                Remark
                              </Label>
                              <Col sm={8}>
                                <Input id="pickupRemark" name="remark" onChange={this.onCreatePickupChange}/>
                              </Col>
                            </FormGroup>
                            <Button
                              style={{
                                position: "absolute",
                                bottom: "0",
                                right: "20px",
                                minWidth: "155px",
                              }}
                              onClick={() => this.submitPickup()}
                            >
                              Send pickup request
                            </Button>
                          </Form>
                        </Col>
                      </Row>
                    </form>
                  </div>
                ) : (
                  <>
                    <div>
                      {/*<DateRangePickerCustom
                      onStartDateChange={(e) => this.setStartDate(e)}
                      onEndDateChange={(e) => this.setEndDate(e)}
                      startDate={startDate}
                      endDate={endDate}
                      focus={focus}
                      onFocusChange={(e) => this.handleFocusChange(e)}
                    />*/}
                      <PickupListTable data={this.state.listPickup} deletePickup={this.deletePickup}
                                       updatePickup={this.updatePickup} key={this.state.pickupStatus}/>
                    </div>
                  </>
                )}
              </ModalBody>
            </div>
          </Modal>


          <Modal
            isOpen={this.state.modalUpload}
            scrollable
            centered
            size='md'
            fullscreen="true |xxl-down'"
            toggle={this.modalUploadToggle}
          >
            <ModalHeader toggle={this.modalUploadToggle}>Upload Tracking</ModalHeader>
            <ModalBody className="text-center">
              <FormGroup>
                <Input onChange={e => this.onChooseFile(e)}
                       accept=".csv"
                       name="file" type="file"/>
              </FormGroup>
              <Button type="button" color="primary" onClick={() => this.uploadTrackingCSV()}>Upload Tracking</Button>
              <a href="/tracking-sample.csv" className="text text-primary pl-3">Tải file tracking mẫu .csv</a>
            </ModalBody>
          </Modal>

        </div>
      );
    }
  }

  function

  mapStateToProps(state) {
    return {
      isFetching: state.packages.isFetching,
      packages: state.packages.packages.rows,
      totalPackages: state.packages.packages.count,
      pickupList: state.pickup,
    };
  }

  export
  default

  connect(mapStateToProps)

(
  Packet
)
  ;
