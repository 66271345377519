import React from 'react'
import axios from 'axios'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button, Card,
  CardBody, CardFooter,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, PaginationItem, PaginationLink,
  Row,
  Table
} from "reactstrap";
import {Link} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import jwt from "jsonwebtoken";
import Pagination from "react-js-pagination";
import {USER_ROLE} from "../../constants";
import ImageUploading from 'react-images-uploading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fileDownload from "js-file-download";

class Customer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 20,
        from: 0,
        page: 1
      },
      modalToggle: false,
      modalEditToggle: false,
      modalToggleUpdateCustomer: false,
      modalCustomerInfo: {},
      currUser: {},
      createCustomer: {
        companyName: "",
        name: "",
        address: "",
        phone: "",
        note: "",
        customerType: "Khách lẻ",
        taxCode: ""
      },
      editCustomer: {
        companyName: "",
        name: "",
        address: "",
        phone: "",
        note: "",
        customerType: "",
        taxCode: ""
      },
      isEdit: false,
      loading: false,
      search: '',
      customerType: "",
      images: [],
      imagesEdit: [],
      searchType: "owner",
      disableDownloadButton: false
    }
    this.onDeleteCustomer = this.onDeleteCustomer.bind(this)
    this.openEditModal = this.openEditModal.bind(this)
    this.onCreateCustomer = this.onCreateCustomer.bind(this);
    this.onEditCustomer = this.onEditCustomer.bind(this)
    this.onEditCustomerChange = this.onEditCustomerChange.bind(this);
    this.toggleUpdateCustomer = this.toggleUpdateCustomer.bind(this);
    this.onCreateCustomerChange = this.onCreateCustomerChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.searchBy = this.searchBy.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.setImages = this.setImages.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    console.log(currUser)
    await this.setState({currUser, createCustomer: {...this.state.createCustomer, owner: currUser.displayName}})
    await this.getAllCustomer()
  }

  async onCreateCustomer(e) {
    try {
      const {createCustomer, images} = this.state
      let imageLists = []
      let inValid = '';
      if (!createCustomer.name || !createCustomer.phone || !createCustomer.address) {
        toast.error('Vui lòng nhập các trường bắt buộc')
        return
      }

      if (images && images.length) {
        const formData = new FormData();
        images.forEach(i => {
          formData.append("photos", i.file);
        })
        const {data = []} = await axios({
          method: "post",
          url: "/upload-customer",
          data: formData,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        imageLists = data
      }


      await axios.post('/create-customer', {
        ...createCustomer,
        images: imageLists.join(',')
      })
      this.setState((prevState) => ({modalToggle: !prevState.modalToggle, images: []}));
      await this.getAllCustomer()
      e.preventDefault();
    } catch (err) {
      let message = err.response.data.message || 'Đã có lỗi xảy ra!'
      toast.error(message);
    }
  }

  async onEditCustomer(e) {
    try {
      const {images, imagesEdit} = this.state
      let imageLists = []
      if (images && images.length) {
        const formData = new FormData();
        images.forEach(i => {
          formData.append("photos", i.file);
        })
        const {data = []} = await axios({
          method: "post",
          url: "/upload-customer",
          data: formData,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        imageLists = data
      }


      if (imagesEdit && imagesEdit.length) {
        imageLists = [...imageLists, ...imagesEdit]
      }
      const {editCustomer} = this.state
      await axios.post(`/update-customer/${editCustomer.id}`, {
        ...editCustomer,
        images: imageLists.join(',')
      })
      this.setState((prevState) => ({modalEditToggle: !prevState.modalEditToggle, images: [], imagesEdit: []}));
      await this.getAllCustomer()
      e.preventDefault();
    } catch (e) {

    }
  }

  toggle() {
    this.setState((prevState, props) => ({modalToggle: !prevState.modalToggle}));
  }

  openEditModal(obj) {
    let imagesEdit = obj.images
    if (imagesEdit) {
      imagesEdit = imagesEdit.split(',')
    }
    this.setState({editCustomer: obj, imagesEdit: imagesEdit})
    this.setState((prevState, props) => ({modalEditToggle: !prevState.modalEditToggle}));
  }

  toggleUpdateCustomer() {

  }

  onCreateCustomerChange(e) {
    const {name, value} = e.target;
    let createCustomerInfo = this.state.createCustomer
    console.log(name)

    createCustomerInfo[name] = value
    this.setState({createCustomer: createCustomerInfo})
  }

  onEditCustomerChange(e) {
    const {name, value} = e.target;
    let editCustomerInfo = this.state.editCustomer
    editCustomerInfo[name] = value
    this.setState({editCustomer: editCustomerInfo})
  }

  formatNumber(num) {
    return num
  }

  async onDeleteCustomer(id) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this Customer?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const res = await axios.get(`/delete-customer/${id}`)
              console.log(res)
              await self.getAllCustomer()
            },
          },
          {
            label: "No",
            onClick: () => {
            },
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  onSelectChange = (e) => {
    const {value} = e
    this.setState({filterDateType: value})
  }

  searchBy(val) {
    this.setState({customerType: val})
  }
  searchByCode(val) {
    this.setState({searchType: val})
  }

  async getAllCustomer(params = {}) {
    try {
      this.setState({loading: true})
      const {search = '', searchType = 'owner', data} = this.state
      params.page = params.page  || data?.page
      let url = `/get-all-customer?page=${params.page || 1}&search=${search}&searchType=${searchType}`
      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      if (this.state.customerType) {
        url += `&customerType=${this.state.customerType}`
      }
      const res = await axios.get(url)
      if (res && res.data) {
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }


  async exportData(params = {}) {
    await this.setState({disableDownloadButton: true})
    const {search = '', searchType = 'owner', data} = this.state
    params.page = params.page  || data?.page
    let url = `/download-customer?page=${params.page || 1}&limit=1000&search=${search}&searchType=${searchType}`
    if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
      url += `&owner=${this.state.currUser.displayName}`
    }
    if (this.state.customerType) {
      url += `&customerType=${this.state.customerType}`
    }

    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `CUSTOMER_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`,
        )
        this.setState({disableDownloadButton: false})
      })
      .catch(() => {
        this.setState({disableDownloadButton: false})
      })
    return
  }


  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllCustomer({page: pageNumber})
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER | role === USER_ROLE.MANAGER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.MANAGER || role === USER_ROLE.CUSTOMER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }

  handleInput = (e) => {
    const {value} = e.target
    this.setState({search: value})
  }
  onSearch = (e) => {
    this.getAllCustomer({search: this.state.search})
  }

  onChange2 = (imageList, addUpdateIndex) => {
    // data for submit
    this.setImages(imageList);
  };

  setImages(imageList) {
    this.setState({images: imageList})
  }

  onImageEditRemove = (index) => {
    let { imagesEdit } = this.state
    const filteredItems = imagesEdit.filter(item => item !== imagesEdit[index])
    this.setState({imagesEdit: filteredItems})
  }


  render() {
    const {data, editCustomer} = this.state
    const customerType = {
      DIV: "Khách lẻ",
      ODD: "Khách sĩ",
      FWD: "Khách FWD"
    }
    const maxNumber = 12;
    return (
      <div>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active> Customer </BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col sm={6}>
            <div style={{marginBottom: '10px'}}>
              <Label style={{marginRight: '8px', width: '108px'}}>Phân loại: </Label>
              <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                      onChange={(e) => this.searchBy(e.target.value)}
              >
                <option value="">Tất cả</option>
                <option value="Khách lẻ">Khách lẻ</option>
                <option value="Khách sĩ">Khách sĩ</option>
                <option value="Khách FWD">Khách FWD</option>
              </select>
            </div>
            <div style={{marginBottom: '10px'}}>
              <Label style={{marginRight: '8px'}}>Tìm kiếm theo:</Label>
              <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                      onChange={(e) => this.searchByCode(e.target.value)}
              >
                <option value="owner">Tên người tạo</option>
                <option value="khCode">Mã KH</option>
                <option value="phone">Số điện thoại</option>
                <option value="khName">Tên khách hàng</option>
              </select>
            </div>
          </Col>
          <Col sm={6} className='ml-auto'>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                this.onSearch()
              }}
            >
              <div className="d-flex">
                <InputGroup>
                  <Input
                    id="kgInput"
                    name="code"
                    value={this.state.search}
                    placeholder='Search'
                    onChange={this.handleInput}
                  />
                  <Button type="submit" className="btn-search ml-2">Search</Button>
                </InputGroup>
              </div>
            </form>
          </Col>
        </Row>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Khách hàng</span>
            {
              this.checkPermission2({}, true) && (
                <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                  {data.list.length > 0 && (
                    <Button
                      disabled={this.state.disableDownloadButton}
                      onClick={() => this.exportData()}
                    >
                      Tải về Excel
                    </Button>
                  )}
                  {this.checkPermission() && (
                    <Button type="button" className="butt ml-auto" onClick={this.toggle}>+ Tạo mới</Button>
                  )}
                </div>
              )
            }
          </CardHeader>
          <CardBody>
            {this.state.loading && (
              <div className="text-center">
                <span className="loader"></span>
              </div>
            )}
            <Table responsive bordered className="mb-0 table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Mã khách hàng</th>
                <th>Tên</th>
                <th>Địa chỉ</th>
                <th>Số điện thoại</th>
                <th>Người tạo</th>
                <th>Phân loại</th>
                <th>Mã số thuế</th>
                <th>Số lần gửi</th>
                <th>Danh sách người nhận</th>
                <th>Note</th>
                {this.checkPermission() && (
                  <th>Action</th>
                )}

              </tr>
              </thead>
              <tbody>
              {data.list &&
              data.list.map((obj, i) => (
                <tr key={i + 1}>
                  <td>{i + 1}</td>
                  <td>KHTP{obj.id}</td>
                  <td>{obj.name}</td>
                  <td>{obj.address}</td>
                  <td>{obj.phone}</td>
                  <td>{obj.owner}</td>
                  <td>{obj.customerType}</td>
                  <td>{obj.customerType === "Khách FWD" ? obj.taxCode : "-"}</td>
                  <td className="text-center">{obj.billupCount || 0}</td>
                  <td className="text-center">
                    {
                      obj.CustomerReceivers && obj.CustomerReceivers.length > 0 && (
                        obj.CustomerReceivers.map((receiver, index) => (
                          <div key={index}>
                            {receiver.receiverName}
                          </div>
                      ))
                    )
                    }
                    <Link to={`/app/customer-receiver/${obj.id}`} className="text-blue">+ Thêm người nhận</Link>
                  </td>
                  <td>{obj.note || ''}</td>
                  {this.checkPermission2() && (
                    <td>
                      <Button style={{marginRight: '5px', marginBottom: '5px'}} type="button" color="info"
                              onClick={(e) => this.openEditModal(obj)}>Edit</Button>
                      {this.state.currUser.role !== USER_ROLE.CUSTOMER && (
                        <Button style={{marginBottom: '5px'}} type="button" color="danger"
                                onClick={(e) => this.onDeleteCustomer(obj.id)}>Delete</Button>

                      )}
                    </td>
                  )}

                </tr>
              ))}
              </tbody>
            </Table>
          </CardBody>

          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={data.page}
            itemsCountPerPage={data.size}
            totalItemsCount={data.total}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            hideFirstLastPages={true}
          />

        </Card>
        <Row>
          {/* Toggle Form Create Customer */}
          <Modal isOpen={this.state.modalToggle} toggle={this.toggle}>
            <Form>
              <ModalHeader toggle={this.toggle}>Thêm Khách hàng</ModalHeader>
              <ModalBody>
                {/*<FormGroup>
                  <Label for="name">Mã khách hàng (*)</Label>
                  <Input type="text" name="khCode" id="khCode" placeholder="Mã Customer" onChange={this.onCreateCustomerChange} required/>
                </FormGroup>*/}
                <FormGroup>
                  <Label for="name">Tên khách hàng (*)</Label>
                  <Input type="text" name="name" id="name" placeholder="Tên Customer"
                         onChange={this.onCreateCustomerChange} required />
                </FormGroup>
                <FormGroup>
                  <Label for="content">Địa chỉ (*)</Label>
                  <Input type="textarea" name="address" id="address" placeholder="address"
                         onChange={this.onCreateCustomerChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Phone (*)</Label>
                  <Input name="phone" id="phone" placeholder="phone" onChange={this.onCreateCustomerChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Company Name</Label>
                  <Input name="companyName" id="companyName" placeholder="companyName"
                         onChange={this.onCreateCustomerChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="customerType">Phân loại</Label>
                  <Input id="customerType" name="customerType" type="select" onChange={this.onCreateCustomerChange}>
                    {Object.values(customerType).map((object, i) => <option className="text-capitalize" value={object}
                                                                            key={i}>{object}</option>)}
                  </Input>
                </FormGroup>
                {this.state.createCustomer.customerType === "Khách FWD" && (
                  <FormGroup>
                    <Label for="taxCode">Mã số thuế (*)</Label>
                    <Input 
                      type="text" 
                      name="taxCode" 
                      id="taxCode" 
                      placeholder="Nhập mã số thuế"
                      onChange={this.onCreateCustomerChange}
                      required
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="price">Note</Label>
                  <Input name="note" id="note" placeholder="note" onChange={this.onCreateCustomerChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Hình ảnh CCCD</Label>
                  <ImageUploading
                    multiple
                    value={this.state.images}
                    onChange={this.onChange2}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <a className="btn btn-primary"
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Chọn hình ảnh
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        {
                          (this.state.images && this.state.images.length) ? (
                            <a onClick={onImageRemoveAll} className="btn btn-danger">Remove all images</a>
                          ) : ''
                        }
                        <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                          {this.state.images.map((image, index) => (
                            <div key={index} className="image-item" style={{marginTop: '10px'}}>
                              <img src={image['data_url']} alt="" width="100" />
                              <div className="image-item__btn-wrapper" style={{marginTop: '8px'}}>
                                <a onClick={() => onImageRemove(index)}>
                                  <img src="/close.png" alt="" width="10" />
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onCreateCustomer(e)}>Create</Button>{' '}
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

          {/* Toggle Form Edit Customer */}
          <Modal isOpen={this.state.modalEditToggle} toggle={this.openEditModal}>
            <Form>
              <ModalHeader toggle={this.openEditModal}>Edit Khách hàng</ModalHeader>
              <ModalBody>
                {/*<FormGroup>
                  <Label for="name">Mã khách hàng</Label>
                  <Input type="text" name="khCode" id="khCode" value={editCustomer.khCode} placeholder="Mã Customer" onChange={this.onEditCustomerChange} />
                </FormGroup>*/}
                <FormGroup>
                  <Label for="name">Tên khách hàng</Label>
                  <Input type="text" name="name" id="name" value={editCustomer.name} placeholder="Tên Customer"
                         onChange={this.onEditCustomerChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="content">Địa chỉ</Label>
                  <Input type="textarea" name="address" value={editCustomer.address} id="address" placeholder="address"
                         onChange={this.onEditCustomerChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Phone</Label>
                  <Input name="phone" id="phone" placeholder="phone" value={editCustomer.phone}
                         onChange={this.onEditCustomerChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Company Name</Label>
                  <Input name="companyName" id="companyName" value={editCustomer.companyName} placeholder="companyName"
                         onChange={this.onEditCustomerChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="customerType">Hình thức thanh toán</Label>
                  <Input id="customerType" name="customerType" value={editCustomer.customerType} type="select"
                         onChange={this.onEditCustomerChange}>
                    {Object.values(customerType).map((object, i) => <option className="text-capitalize" value={object}
                                                                            key={i}>{object}</option>)}
                  </Input>
                </FormGroup>
                {editCustomer.customerType === "Khách FWD" && (
                  <FormGroup>
                    <Label for="taxCode">Mã số thuế (*)</Label>
                    <Input 
                      type="text" 
                      name="taxCode" 
                      id="taxCode" 
                      value={editCustomer.taxCode}
                      placeholder="Nhập mã số thuế"
                      onChange={this.onEditCustomerChange}
                      required
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="price">Note</Label>
                  <Input name="note" id="note" placeholder="note" value={editCustomer.note}
                         onChange={this.onEditCustomerChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="price">Hình ảnh CCCD</Label>
                  {
                    (this.state.imagesEdit && this.state.imagesEdit.length) ? (
                      <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                        {this.state.imagesEdit.map((image, index) => (
                          <div key={index} className="image-item" style={{marginTop: '10px', position: 'relative'}}>
                            <img src={`/customers/${image}`} alt="" width="100" />
                            <div className="image-item__btn-wrapper" style={{marginTop: '8px', position: 'absolute', right: 0,
                              top: 0}}>
                              <a onClick={() => this.onImageEditRemove(index)}>
                                <img src="/close.png" alt="" width="10" />
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : ''
                  }


                  <ImageUploading
                    multiple
                    value={this.state.images}
                    onChange={this.onChange2}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                      // write your building UI
                      <div className="upload__image-wrapper"
                           style={{marginTop: '30px'}}>
                        <a className="btn btn-primary"
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Chọn thêm hình ảnh
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        {
                          (this.state.images && this.state.images.length) ? (
                            <a onClick={onImageRemoveAll} className="btn btn-danger">Remove all images</a>
                          ) : ''
                        }
                        <div className="image-list" style={{display: 'flex', flexWrap: 'wrap', gridGap: '15px'}}>
                          {this.state.images.map((image, index) => (
                            <div key={index} className="image-item" style={{marginTop: '10px'}}>
                              <img src={image['data_url']} alt="" width="100" />
                              <div className="image-item__btn-wrapper" style={{marginTop: '8px'}}>
                                <a onClick={() => onImageRemove(index)}>
                                  <img src="/close.png" alt="" width="10" />
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onEditCustomer(e)}>Update</Button>{' '}
                <Button color="secondary" onClick={this.openEditModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

        </Row>
      </div>
    )
  }
}

export default Customer
